import { useEffect, useState }   from 'react'
import BackToConcepts            from '../../components/BackToConcepts/BackToConcepts'
import ConceptHeader             from '../../components/ConceptHeader/ConceptHeader'
import css                       from './BoxShadowGenerator.module.css'

const getBoxShadowValue = ({ horizontal, vertical, blur, spread, color, opacity, type })=> {
  const shadowType = type === 'inset' ? type : ''
  const reach = `${horizontal || 0}px ${vertical || 0}px ${blur || 0}px ${spread || 0}px`
  const red = parseInt(color.substring(1, 3), 16)
  const green = parseInt(color.substring(3, 5), 16)
  const blue = parseInt(color.substring(5, 7), 16)
  const shadowColor = `rgba(${red}, ${green}, ${blue}, ${(opacity/100).toFixed(2)})`
  return `${shadowType} ${reach} ${shadowColor}`
}

const ShadowBoxCSS = ({ horizontal, vertical, blur, spread, color, opacity, type })=> {
  const value = getBoxShadowValue({ horizontal, vertical, blur, spread, color, opacity, type })
  return <code><strong>box-shadow: </strong> {value}<strong>;</strong></code>
}

const ValueSlider = ({ label, unit='px', min=-200, max=200, value, onChange })=> (
  <div className={css.slider}>
    <label className={css.sliderLabel} htmlFor={label.toLowerCase()}>{label}</label>
    <div className={css.sliderValueContainer}>
      <input className={css.sliderValue} type='number' value={value} onChange={onChange} />
      <span className={css.sliderUnit}>{unit}</span>
    </div>
    <input 
      className={css.sliderRange} 
      id={label.toLowerCase()} 
      type='range' 
      min={min} 
      max={max} 
      value={value} 
      onChange={onChange} 
    />
  </div>
)

const ColorPicker = ({ label, value, onChange })=> (
  <div className={css.color}>
    <label className={css.colorLabel} htmlFor={label.toLowerCase()}>{label}</label>
    <input 
      className={css.colorValue} 
      id={label.toLowerCase()} 
      type='color' 
      value={value} 
      onChange={onChange} 
    />
  </div>
)

const Separator = ()=> <hr className={css.separator} />

const BoxShadowGenerator = ()=> {

  const [shadow, setShadow] = useState({ 
    horizontal: 10, 
    vertical: 10, 
    blur: 5, 
    spread: 0,
    color: '#000000',
    opacity: 75,
    type: 'outset'
  })

  const [area, setArea] = useState({
    box: '#e7a61a',
    background: '#ffffff'
  })

  const [copy, setCopy] = useState('copy')

  useEffect(()=> {
    let isCurrent = true
    if (copy !== 'copy') setTimeout(()=> isCurrent && setCopy('copy'), 750)
    return ()=> isCurrent = false
  }, [copy])

  const updateShadow = property=> (e)=> {
    const val = e.target.value
    setShadow(current=> ({ ...current, [property]: val }))
  }

  const updateArea = property=> (e)=> {
    const val = e.target.value
    setArea(current=> ({ ...current, [property]: val }))
  }

  const handleCopyCSS = ()=> {
    if (copy !== 'copy') return
    const range = document.createRange()
    range.selectNode(document.querySelector('#box-shadow-generator code'))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
    setCopy('copied!')
  }

  return (
    <>
      <ConceptHeader title='Box Shadow Generator' />
      <div id='box-shadow-generator' className={css.main}>
        <div className={css.content}>
          <div className={css.controls}>
            <ValueSlider label='Horizontal' value={shadow.horizontal} onChange={updateShadow('horizontal')} />
            <ValueSlider label='Vertical' value={shadow.vertical} onChange={updateShadow('vertical')} />
            <ValueSlider label='Blur' min={0} max={300} value={shadow.blur} onChange={updateShadow('blur')} />
            <ValueSlider label='Spread' value={shadow.spread} onChange={updateShadow('spread')} />
            <Separator />
            <ColorPicker label='Shadow color' value={shadow.color} onChange={updateShadow('color')} />
            <ValueSlider label='Shadow opacity' value={shadow.opacity} min={0} max={100} unit='%' onChange={updateShadow('opacity')} />
            <Separator />
            <ColorPicker label='Box color' value={area.box} onChange={updateArea('box')} />
            <ColorPicker label='Background color' value={area.background} onChange={updateArea('background')} />
          </div>
          <div className={css.preview} style={{ backgroundColor: area.background }}>
            <div 
              className={css.previewBox}
              style={{ backgroundColor: area.box, boxShadow: getBoxShadowValue(shadow) }}
            >
              <div className={css.previewBoxLabel}>
                <div className={css.previewBoxLabelCopy} onClick={handleCopyCSS}>{copy}</div>
                <ShadowBoxCSS {...shadow} />
              </div>
            </div>
          </div>
        </div>
        <p className={css.block}>
          This concept was inspired by <strong>CSSMatic</strong>, a non-profit project, made by developers for developers. 
          I recently saw that their SSL Certificate was expired and figured the project was in bad shape. Since it's a tool I 
          use every now and then, I decided to bring the idea to my site, do a few changes, and keep it here for me to use.
        </p>
      </div>
      <BackToConcepts />
    </>
  )

}

export default BoxShadowGenerator