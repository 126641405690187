import css from './Cover.module.css'

const Cover = () => (
  <div className={css.main}>
    <div className={css.text}>
      <span className={css.letter}>{'{'}</span>
      <span className={css.dot} />
      <span className={css.dot} />
      <span className={css.dot} />
      <span className={css.letter}>{'}'}</span>
    </div>
  </div>
)

export default Cover