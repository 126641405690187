import { Link }     from 'react-router-dom'
import css          from './BackToConcepts.module.css'

const BackToConcepts = ({ label='More concepts' })=> (
  <div className={css.main}>
    <Link to='/concepts' className={css.link}>
      {label}
    </Link>
  </div>
)

export default BackToConcepts