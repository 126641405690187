import { useEffect, useRef, useState }                                from 'react'
import { blur, darken, grayscale, hueRotate, invert, lighten, sepia } from './resources/image'
import { getMedia, getMediaImage }                                    from './resources/media'
import css                                                            from './SelfieStudio.module.css'

const WebcamStatus = {
  PENDING: 'PENDING',
  OK: 'OK',
  ACCESS_DENIED: 'ACCESS_DENIED',
  NOT_FOUND: 'NOT_FOUND',
  ERROR: 'ERROR'
}

const WebcamStatusIndicator = ({ status })=> {
  if (status === WebcamStatus.OK) return null
  const type = (status === WebcamStatus.PENDING) ? 'warning' : 'error'
  return (
    <div className={css.snack} data-type={type}>
      <p className={css.message}>
        {status === WebcamStatus.PENDING && 'Waiting to get feed from the camera'}
        {status === WebcamStatus.ACCESS_DENIED && 'You are not allowing me to access the camera'}
        {status === WebcamStatus.NOT_FOUND && 'Could not find a suitable camera to work'}
        {status === WebcamStatus.ERROR && 'There was an error getting the camera\'s data'}
      </p>
    </div>
  )
}

const SelfieStudio = ()=> {

  const [webcamStatus, setWebcamStatus] = useState(WebcamStatus.PENDING)
  const [isImageReady, setImageReady] = useState(false)
  const videoRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(()=> {
    getMedia()
      .then(stream=> {
        videoRef.current.srcObject = stream
        videoRef.current.play()
        setWebcamStatus(WebcamStatus.OK)
      })
      .catch(err=> {
        if (err.name === 'NotAllowedError') return setWebcamStatus(WebcamStatus.ACCESS_DENIED)
        return setWebcamStatus(WebcamStatus.ERROR)
      })
  }, [])

  const handleSnapshot = ()=> {
    getMediaImage(videoRef.current, canvasRef.current)
    setImageReady(true)
  }

  const transformToGrayscale = ()=> grayscale(canvasRef.current)
  const transformDark = ()=> darken(canvasRef.current)
  const transformLight = ()=> lighten(canvasRef.current)
  const transformSepia = ()=> sepia(canvasRef.current)
  const transformBlur = ()=> blur(canvasRef.current)
  const transformHue = ()=> hueRotate(canvasRef.current)
  const transformInvert = ()=> invert(canvasRef.current)

  const handleBack = ()=> setImageReady(false)


  return (
    <div className={css.main}>
      <WebcamStatusIndicator status={webcamStatus} />
      <div 
        className={css.area}
        data-status={webcamStatus === WebcamStatus.OK ? 'ok' : 'ko'}
      >
        <video 
          ref={videoRef} 
          className={css.video} 
          data-status={isImageReady ? 'ko' : 'ok'}
          onClick={handleSnapshot} 
        />
        <canvas 
          ref={canvasRef} 
          className={css.canvas} 
          data-status={isImageReady ? 'ok' : 'ko'}
        />
        <div 
          className={css.controls}
          data-status={isImageReady ? 'ok' : 'ko'}
        >
          <button className={css.button} data-transform='grayscale' onClick={transformToGrayscale} />
          <button className={css.button} data-transform='darken' onClick={transformDark} />
          <button className={css.button} data-transform='lighten' onClick={transformLight} />
          <button className={css.button} data-transform='sepia' onClick={transformSepia} />
          <button className={css.button} data-transform='blur' onClick={transformBlur} />
          <button className={css.button} data-transform='hue' onClick={transformHue} />
          <button className={css.button} data-transform='invert' onClick={transformInvert} />
          <button className={css.button} data-transform='back' onClick={handleBack} />
        </div>
      </div>
    </div>
  )
}

export default SelfieStudio