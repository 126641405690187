import Heart  from '../../elements/Heart/Heart'
import css    from './ComingSoonCard.module.css'

const ComingSoonCard = ({ text='More content soon' })=> (
  <div className={css.card}>
    <div className={css.clock} />
    <div className={css.title}>
      {text}
    </div>
    <Heart />
  </div>
)

export default ComingSoonCard