import { MonospaceRenderer }  from './ArtASCII'
import css                 from './Cover.module.css'

const cover_text = `
       ___         ___           ___           ___           ___
      /\\  \\       /\\  \\         /\\  \\         /\\  \\         /\\  \\
      \\:\\  \\     /::\\  \\       /::\\  \\       /::\\  \\       /::\\  \\
  ___ /::\\__\\   /:/\\:\\  \\     /:/\\:\\  \\     /:/\\:\\  \\     /:/\\:\\  \\
 /\\  /:/\\/__/  /:/  \\:\\  \\   /::\\~\\:\\  \\   /:/  \\:\\  \\   /::\\~\\:\\  \\
 \\:\\/:/  /    /:/__/ \\:\\__\\ /:/\\:\\ \\:\\__\\ /:/__/_\\:\\__\\ /:/\\:\\ \\:\\__\\
  \\::/  /     \\:\\  \\ /:/  / \\/_|::\\/:/  / \\:\\  /\\ \\/__/ \\:\\~\\:\\ \\/__/
   \\/__/       \\:\\  /:/  /     |:|::/  /   \\:\\ \\:\\__\\    \\:\\ \\:\\__\\
                \\:\\/:/  /      |:|\\/__/     \\:\\/:/  /     \\:\\ \\/__/
                 \\::/  /       |:|  |        \\::/  /       \\:\\__\\
                  \\/__/         \\|__|         \\/__/         \\/__/
`
const Cover = ()=> (
  <div className={css.cover}>
    <MonospaceRenderer className={css.signature} text={cover_text} />
  </div>
)

export default Cover