import { useState } from "react"

const CopyToClipboardButton = ({ 
  className,
  label = 'Copy to clipboard',
  copiedLabel = 'Copied!',
  clipboardContent = '', 
  disabledDuration = 1800
 }) => {

  const [copied, setCopied] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const handleClick = async () => {
    await navigator.clipboard.writeText(clipboardContent)
    setCopied(true)
    setDisabled(true)
    setTimeout(() => {
      setCopied(false)
      setDisabled(false)
    }, disabledDuration)
  }

  return (
    <button 
      className={className} 
      onClick={handleClick} 
      disabled={disabled}
    >
      {copied ? copiedLabel : label}
    </button>
  )
}

export default CopyToClipboardButton