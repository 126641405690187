import { useState, useEffect } from 'react'
import ConceptHeader           from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts          from '../../components/BackToConcepts/BackToConcepts'
import CodeArea                from '../../components/CodeArea/CodeArea'
import { CodeLine }            from '../../components/CodeArea/CodeArea'
import css                     from './SpeechParser.module.css'

const CanIUseLink = ()=> (
  <a
    className={css.link}
    href='https://caniuse.com/#feat=speech-recognition'
  >
    Can I Use
  </a>
)

const SpeechParser = ()=> {

  const [isListening, setListening] = useState(false)
  const [isSupported, setSupported] = useState(false)
  const [speechResult, setSpeechResult] = useState({ value: '', confidence: '' })

  useEffect(()=> {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList
    if (SpeechRecognition && SpeechGrammarList) setSupported(true)
  }, [])

  const handleClick = ()=> {
    if (isListening) return
    if (!isSupported) return
    setListening(true)
    setSpeechResult({ value: '', confidence: '' })
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList

    const recognition = new SpeechRecognition()
    recognition.grammars = new SpeechGrammarList()
    recognition.lang = 'en-US'
    recognition.interimResults = false
    recognition.maxAlternatives = 1
    recognition.start()
    // Actual operation - When we have an actionable result
    recognition.onresult = event=> {
      const result = event.results[0][0]
      const { transcript, confidence } = result
      setSpeechResult({ value: transcript, confidence: `${(100 * confidence).toFixed(2)}%` })
    }
    // Actual operation - When we no longer have a speech
    recognition.onspeechend = ()=> {
      recognition.stop()
      setListening(false)
    }
    // Actual operation - Error handler
    recognition.onerror = ()=> {
      setListening(false)
    }
  }

  return (
    <>
      <ConceptHeader title='Speech Parser using Web APIs' />
        <div className={css.main}>
          <p className={css.block}>
            Speech recognition (or speech parsing) is the technology for turning speech (audio) to text. It's a technology 
            involving phonetic detection and having a language grammar available for matching sounds to words.
          </p>
          <div className={css.interaction}>
            <button
              onClick={handleClick}
              data-status={isListening ? 'active' : 'idle'}
              className={css.button}
            >
              {'🎙️'}
            </button>
            {isSupported && (
              <>
                <p className={css.result}>
                  “{speechResult.value || '...'}”
                </p>
                <p className={css.confidence}>
                  <strong>Confidence:</strong> {speechResult.confidence || '...'}
                </p>
              </>
            )}
            {!isSupported && (
              <p className={css.error}>
                This browser doesn't support speech parsing yet. You will find a link with support details 
                at the bottom of this page.
              </p>
            )}
          </div>
          <p className={css.block}>
            Voice assistants use this technology, and they perform reasonably well, but it's hard to do. It involves some machine
            learning algorithms and a lot of language data. Something rather expensive to do. But, browsers are implementing
            a native web API for handling all this with "ease". Providing the necessary methods and language tools for transcribing
            audio.
          </p>
          <CodeArea>
            <CodeLine fragments={[
              { content: 'const', color: 'orange' }, 
              { content: ' SpeechRecognition', color: 'blue' }, 
              { content: ' = '},
              { content: 'window', color: 'yellow'},
              { content: '.'},
              { content: 'SpeechRecognition', color: 'violet-2'},
              { content: ' || '},
              { content: 'window', color: 'yellow'},
              { content: '.'},
              { content: 'webkitSpeechRecognition', color: 'violet-2'},
            ]} />
            <CodeLine fragments={[
              { content: 'const', color: 'orange' }, 
              { content: ' SpeechGrammarList', color: 'blue' }, 
              { content: ' = '},
              { content: 'window', color: 'yellow'},
              { content: '.'},
              { content: 'SpeechGrammarList', color: 'violet-2'},
              { content: ' || '},
              { content: 'window', color: 'yellow'},
              { content: '.'},
              { content: 'webkitSpeechGrammarList', color: 'violet-2'},
            ]} />
            <CodeLine fragments={[
              { content: 'const', color: 'orange' }, 
              { content: ' recognition', color: 'blue-2' }, 
              { content: ' = '},
              { content: 'new ', color: 'orange'},
              { content: ''},
              { content: 'SpeechRecognition', color: 'blue'},
              { content: '()'},
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'grammars', color: 'yellow'},
              { content: ' = '},
              { content: 'new ', color: 'orange'},
              { content: 'SpeechGrammarList', color: 'blue'},
              { content: '()'},
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'lang', color: 'yellow'},
              { content: ' = '},
              { content: '"en-US"', color: 'green'},
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'interimResults', color: 'yellow'},
              { content: ' = '},
              { content: 'false', color: 'orange'},
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'maxAlternatives', color: 'yellow'},
              { content: ' = '},
              { content: '1', color: 'orange'},
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'start', color: 'yellow'},
              { content: '()'},
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onresult', color: 'yellow'},
              { content: ' = '},
              { content: 'event', color: 'wood'},
              { content: '=> {', color: 'green-2'},
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'const ', color: 'orange'},
              { content: 'result', color: 'violet-3' }, 
              { content: ' = '},
              { content: 'event', color: 'wood'},
              { content: '.'},
              { content: 'results', color: 'yellow'},
              { content: '[', color: 'pink'},
              { content: '0', color: 'orange'},
              { content: ']', color: 'pink'},
              { content: '[', color: 'pink'},
              { content: '0', color: 'orange'},
              { content: ']', color: 'pink'},
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'const ', color: 'orange'},
              { content: '{ ', color: 'orange' }, 
              { content: 'transcript', color: 'yellow'},
              { content: ', '},
              { content: 'confidence', color: 'yellow'},
              { content: ' }', color: 'orange'},
              { content: ' = '},
              { content: 'result', color: 'violet-3'},
            ]} />
            <CodeLine indent={1} fragments={[
              { content: '// The "transcript" contains the text ', color: 'grey'},
            ]} />
            <CodeLine indent={1} fragments={[
              { content: '// The "confidence" is a 0 (lowest) to 1 (highest) scale', color: 'grey'},
            ]} />
            <CodeLine fragments={[
              { content: '} ', color: 'green-2' }, 
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onspeechend', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'recognition', color: 'blue-2'},
              { content: '.'},
              { content: 'stop', color: 'yellow' },
              { content: '()'},
            ]} />
          </CodeArea>
          <p className={css.block}>
            This is the minimal code necessary to handle speech parsing. To actually do something useful, a number of 
            additional steps should be performed. For one, support sucks, this only works in <code>webkit</code> browsers 
            (as of early 2020) - so the first step is checking the API will work or throw an error when creating 
            the <code>SpeechRecognition</code> instance.
          </p>
          <p className={css.block}>
            There are also a number of scenarios we haven't taken into account, such as errors, or a lot of noise in the audio,
            or foreign words... The API provides many additional listeners, and there should be a specific handler for each.
          </p>
          <CodeArea>
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onaudiostart', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Now capturing live audio"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onaudioend', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Finished capturing live audio"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onend', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Recognition service has ended successfully"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onnomatch', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] No comprehensible speech found (Either noise or not passing the threshold)"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onsoundstart', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Sounds (speech or noise) detected"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onsoundend', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Sounds (speech or noise) not detected anymore"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onspeechstart', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Comprehensible speech detected"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onstart', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Started"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: 'recognition', color: 'blue-2' }, 
              { content: '.'},
              { content: 'onerror', color: 'yellow'},
              { content: ' = '},
              { content: '()=> ', color: 'orange' },
              { content: 'console', color: 'blue'},
              { content: '.'},
              { content: 'log(', color: 'yellow' },
              { content: '"[Speech Parser] Error capturing audio"', color: 'green'},
              { content: ')', color: 'yellow' },
            ]} />
          </CodeArea>
          <p className={css.block}>
            As I've mentioned before, support for this as of today (early 2020) is bad, to say the least. Don't present
            this as the killer cross-browser feature that's going to change the game, because it's still too soon. Check 
            out all the details here: <CanIUseLink />
          </p>
        </div>
      <BackToConcepts />
    </>
  )
}

export default SpeechParser