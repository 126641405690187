
export const BOARD_SIZE_NORMAL = 5
export const BOARD_SIZE_HARD = 7

export const generateBoard = (difficulty)=> {
  const size = difficulty === 'normal' ? BOARD_SIZE_NORMAL : BOARD_SIZE_HARD
  const board = Array(size).fill().map(() => Array(size).fill(false))
  for (let i=0; i<size*size; i++) {
    if (Math.random() > 0.5) {
      toggle(board, Math.floor(i/size), i%size);
    }
  }
  return board;
}

export const toggle = (board, row, col)=> {
  const size = board.length
  board[row][col] = !board[row][col];
  if (row > 0) board[row - 1][col] = !board[row - 1][col];
  if (row < size - 1) board[row + 1][col] = !board[row + 1][col];
  if (col > 0) board[row][col - 1] = !board[row][col - 1];
  if (col < size - 1) board[row][col + 1] = !board[row][col + 1];
}