import css from './Cover.module.css'

const Cover = () => (
  <div className={css.main}>
    <div className={css.scissors}>
      <div className={css.half}>
        <div className={css.blade} />
        <div className={css.handle} />
      </div>
      <div className={css.half}>
        <div className={css.blade} />
        <div className={css.handle} />
      </div>
      <div className={css.joint} />
    </div>
  </div>
)

export default Cover