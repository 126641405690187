import { useState, useRef, useLayoutEffect }  from 'react'
import css                                    from './Whiteboard.module.css'

const drawLine = (canvas, x0, y0, x1, y1, color, lineWidth)=> {
  if (!canvas || !canvas.current) return false
  const context = canvas.current.getContext('2d')
  context.beginPath()
  context.moveTo(x0, y0)
  context.lineTo(x1, y1)
  if (color === 'transparent') context.globalCompositeOperation = 'destination-out'
  else {
    context.globalCompositeOperation = 'source-over'
    context.strokeStyle = color
  }
  context.lineWidth = lineWidth
  context.stroke()
  context.closePath()
  return true
}

const clear = canvas=> {
  if (!canvas || !canvas.current) return false
  canvas.current.getContext('2d').clearRect(0, 0, canvas.current.width, canvas.current.height)
}

const WhiteboardMarker = ({ color, brushColor, onClick})=> (
  <div
    className={css.marker}
    style={{
      backgroundColor: color === brushColor ? color : `${color}a0`,
      border: color === brushColor ? `2px solid ${color}` : 'none'
    }}
    onClick={()=> onClick({ color, lineWidth: 2 })}
  />
)

const WhiteboardEraser = ({ onClick })=> <div className={css.eraser} onClick={onClick}>↫</div>

const WhiteboardClear = ({ onClick })=> <div className={css.clear} onClick={onClick}>⟲</div>

const WhiteboardDownload = ({ onClick })=> <div className={css.download} onClick={onClick}>⇩</div>

const Whiteboard = ()=> {

  const [isDrawing, setDrawing] = useState(false)
  const [brush, setBrush] = useState({ color: '#f2f2f2', lineWidth: 2 })
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isCanvasReady, setCanvasReady] = useState(false)

  const canvas = useRef(null)

  useLayoutEffect(()=> {

    const cancelCanvasTouch = e=> (e.target === canvas.current) && e.preventDefault()

    document.body.addEventListener('touchstart', cancelCanvasTouch)
    document.body.addEventListener('touchend', cancelCanvasTouch)
    document.body.addEventListener('touchmove', cancelCanvasTouch)

    return ()=> {
      document.body.removeEventListener('touchstart', cancelCanvasTouch)
      document.body.removeEventListener('touchend', cancelCanvasTouch)
      document.body.removeEventListener('touchmove', cancelCanvasTouch)
    }

  })

  useLayoutEffect(()=> {
    if (isCanvasReady) return
    canvas.current.width = canvas.current.getBoundingClientRect().width
    canvas.current.height = canvas.current.getBoundingClientRect().height
    setCanvasReady(true)
  }, [isCanvasReady])

  useLayoutEffect(()=> {
    const resize = ()=> {
      canvas.current.width = canvas.current.getBoundingClientRect().width
      canvas.current.height = canvas.current.getBoundingClientRect().height
    }
    window.addEventListener('resize', resize)
    return ()=> window.removeEventListener('resize', resize)
  }, [])

  const throttle = (callback, delay)=> {
    let previousCall = new Date().getTime()
    return function() {
      const time = new Date().getTime()
      if ((time - previousCall) >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }

  const handleClearBoard = ()=> clear(canvas)

  const handleMouseDown = e=> {
    const { clientY, clientX } = e
    setDrawing(true)
    const { x, y } = canvas.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }

  const handleMouseMove = e=> {
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { x, y } = canvas.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(canvas, position.x, position.y, endX, endY, brush.color, brush.lineWidth)
    setPosition({ x: endX, y: endY })
  }

  const handleMouseLeave = e=> {
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { x, y } = canvas.current.getBoundingClientRect()
    const endX = clientX - x
    const endY= clientY - y
    drawLine(canvas, position.x, position.y, endX, endY, brush.color, brush.lineWidth)
    setDrawing(false)
  }

  const handleTouchDown = e=> {
    const { clientY, clientX } = e.touches[0]
    setDrawing(true)
    const { x, y } = canvas.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }

  const handleTouchMove = e=> {
    if (!isDrawing) return
    const { clientY, clientX } = e.touches[0]
    const { x, y } = canvas.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(canvas, position.x, position.y, endX, endY, brush.color, brush.lineWidth)
    setPosition({ x: endX, y: endY })
  }

  const handleTouchLeave = ()=> {
    if (!isDrawing) return
    setDrawing(false)
  }

  const handleDownload = ()=> {
    const faux_canvas = document.createElement("canvas")
    faux_canvas.width = canvas.current.width
    faux_canvas.height = canvas.current.height
    const faux_context = faux_canvas.getContext('2d')
    faux_context.fillStyle = '#fff'
    faux_context.fillRect(0,0, canvas.current.width, canvas.current.height)
    faux_context.drawImage(canvas.current, 0, 0)
    const data = faux_canvas.toDataURL('image/jpeg')
    const faux_link = document.createElement("a")
    faux_link.href = data
    faux_link.download = 'board.jpg'
    document.body.appendChild(faux_link)
    faux_link.click()
    document.body.removeChild(faux_link)
  }

  return (
    <div className={css.main}>
      <canvas
        className={css.canvas}
        ref={canvas}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseLeave}
        onMouseOut={handleMouseLeave}
        onMouseMove={throttle(handleMouseMove, 10)}
        onTouchStart={handleTouchDown}
        onTouchEnd={handleTouchLeave}
        onTouchCancel={handleTouchLeave}
        onTouchMove={throttle(handleTouchMove, 10)}
      />
      <div className={css.controls}>
        <div className={css.buttons}>
          <WhiteboardMarker color='#f2f2f2' brushColor={brush.color} onClick={setBrush} />
          <WhiteboardMarker color='#f15a29' brushColor={brush.color} onClick={setBrush} />
          <WhiteboardMarker color='#8dc63f' brushColor={brush.color} onClick={setBrush} />
          <WhiteboardMarker color='#4682b4' brushColor={brush.color} onClick={setBrush} />
          <WhiteboardMarker color='#fbbc40' brushColor={brush.color} onClick={setBrush} />
          <WhiteboardEraser onClick={()=> setBrush({ color: 'transparent', lineWidth: 30 })} />
        </div>
        <div className={css.buttons}>
          <WhiteboardClear onClick={handleClearBoard} />
          <WhiteboardDownload onClick={handleDownload} />
        </div>
      </div>
    </div>
  )
}

export default Whiteboard