import { useState, useEffect }  from 'react'
import css                      from './Cover.module.css'

const Cover = () => {

  const [letter, setLetter] = useState('A')

  useEffect(()=> {
    const interval = setInterval(()=> {
      const LETTERS = "ABCDEFGHIJKLMOPQRSTUVWXYZ"
      const index = (LETTERS.indexOf(letter) + 1) % (LETTERS.length)
      setLetter(LETTERS[index])
    }, 3000)
    return ()=> clearInterval(interval)
  })


  return (
    <div className={css.main}>
      <span className={css.pulse}>{letter}</span>
    </div>
  )
}

export default Cover