import { Link }           from 'react-router-dom'
import CalendarIcon       from '../../elements/CalendarIcon/CalendarIcon'
import css                from './ConceptCard.module.css'

const color = tag=> {
  if (tag === 'CSS') return 'orange'
  if (tag === 'Agile') return 'pink'
  if (tag === 'JavaScript') return 'gold'
  if (tag === 'MongoDB') return 'green'
  if (tag === 'Web 3') return 'blue'
  return 'default'
}

const ConceptCard = ({ to, content, title, tag, tag_color, date })=> (
  <div className={css.card}>
    <Link to={to} className={css.header}>{content}</Link>
    <div className={css.details}>
      <div 
        className={css.tag} 
        data-color={tag_color || color(tag)}
      >
        {tag}
      </div>
      <div>
        <CalendarIcon className={css.calendar} />
        {date}
      </div>
    </div>
    <Link to={to} className={css.title}>{title}</Link>
  </div>
)

export default ConceptCard