import css from './UnsplashedCredit.module.css'

const UnsplashedCredit = ({ author, handle })=> (
  <a 
    className={css.credit}
    href={`https://unsplash.com/${handle}?utm_medium=referral&utm_campaign=photographer-credit&utm_content=creditBadge`}
    target="_blank" 
    rel="noopener noreferrer" 
    title={`Download free do whatever you want high-resolution photos from ${author}`}
  >
    <span className={css.content}>
      <svg className={css.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>unsplash-logo</title>
        <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z" />
      </svg>
    </span>
    <span className={css.author}>{author}</span>
  </a>
)

export default UnsplashedCredit