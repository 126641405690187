import { useState }   from 'react'
import BackToConcepts from '../../components/BackToConcepts/BackToConcepts'
import ConceptHeader  from '../../components/ConceptHeader/ConceptHeader'
import css            from './FontsMatter.module.css'

const Conclusion = ({ type })=> {
  if (type === 'adorable') return (
    <>
      "Well okay, the stranger is probably one of the company's clients, and she had lunch with him.
      After lunch she went home directly, and is waiting there. I'll meet her there."
    </>
  )
  else if (type === 'creepy') return (
    <>
      <strong>"What the f**k!</strong> Did the stranger just kidnap her? Is she dead? Should I call the police? Damn! I
      can't believe the last thing I said to her this morning is 'You smell like a fart'! Something is
      clearly wrong!"
    </>
  )
}

const ComicSansCriminal = ()=> (
  <a 
    href='https://www.comicsanscriminal.com/'
    target='_blank' 
    rel='noopener noreferrer'
  >
    Comic Sans Criminal
  </a>
)

const FontsMatter = ()=> {

  const [type, setType] = useState('adorable')

  const handleClick = ()=> setType(current=> current === 'adorable' ? 'creepy' : 'adorable')

  return (
    <>
      <ConceptHeader title='Fonts matter!' />
      <div className={css.main}>
        <p className={css.block}>
          It may not seem obvious at first, but fonts have a purpose. They have been designed for a specific 
          audience and type of messages. Some are easier to read, some are more elegant, some are specially
          designed for people with dyslexia...
        </p>
        <p className={css.block}>
          You can't just pick any random font from the Internet for a project, you have to make sure it's the right
          fit, and, since there are <strong>a lot</strong> of fonts available for free, you are very likely to find
          a bunch that fit your needs. The wrong font can indeed send the wrong message, and here's why
        </p>
        <p className={css.block}>
          Say you are off work, and you are on your way to pick your sister from her office. As soon as you get
          there, the receptionist tells you she left early with a stranger and hands you a note that she left for
          you. The note reads as follows:
        </p>
        <div className={css.example}>
          <h3 className={css.exampleText} data-type={type} onClick={handleClick}>
            I will be waiting for you at home
          </h3>
        </div>
        <p className={css.block}>
          At this point, you are probably thinking:
        </p>
        <p className={css.storyblock}>
          <Conclusion type={type} />
        </p>
        <div className={css.separator}>
          <div className={css.separatorDot} />
          <div className={css.separatorDot} />
          <div className={css.separatorDot} />
        </div>
        <p className={css.block}>
          Click on the note to see a completely different story, simply using <strong>the power of fonts</strong>.
        </p>
        <p className={css.block}>
          So please, do some font research and pick the right one for your project. And above all, you must never
          become a <ComicSansCriminal />.
        </p>
      </div>
      <BackToConcepts />
    </>
  )

}

export default FontsMatter