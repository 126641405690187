import css    from './Cover.module.css'

const NFTIcon = ({ index })=> (
  <svg className={css.nftIcon} data-index={index} viewBox="0 0 256 256">
    <g fill="white" transform="translate(128 128) scale(0.72 0.72)">
      <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
        <path d="M 45 69.563 c -1.226 0 -2.452 -0.315 -3.545 -0.946 L 26.32 59.879 c -2.187 -1.263 -3.545 -3.615 -3.545 -6.141 V 36.261 c 0 -2.524 1.358 -4.877 3.545 -6.14 l 15.135 -8.739 c 2.187 -1.262 4.904 -1.262 7.09 0 l 15.136 8.739 c 2.187 1.263 3.545 3.615 3.545 6.14 v 17.477 c 0 2.524 -1.358 4.878 -3.545 6.141 l -15.136 8.738 C 47.452 69.248 46.226 69.563 45 69.563 z M 45 22.436 c -0.88 0 -1.76 0.227 -2.545 0.68 L 27.32 31.854 c -1.57 0.906 -2.545 2.595 -2.545 4.407 v 17.477 c 0 1.813 0.975 3.502 2.545 4.408 l 15.135 8.738 c 1.571 0.906 3.521 0.906 5.09 0 l 15.136 -8.738 c 1.569 -0.906 2.545 -2.596 2.545 -4.408 V 36.261 c 0 -1.812 -0.976 -3.501 -2.545 -4.407 l -15.136 -8.739 C 46.76 22.662 45.88 22.436 45 22.436 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 37.691 52.733 c -0.339 0 -0.664 -0.174 -0.85 -0.473 l -6.509 -10.487 v 9.959 c 0 0.553 -0.448 1 -1 1 s -1 -0.447 -1 -1 V 38.267 c 0 -0.447 0.296 -0.839 0.726 -0.962 c 0.431 -0.124 0.889 0.055 1.124 0.435 l 6.509 10.486 v -9.959 c 0 -0.552 0.448 -1 1 -1 s 1 0.448 1 1 v 13.467 c 0 0.446 -0.296 0.839 -0.726 0.962 C 37.875 52.721 37.783 52.733 37.691 52.733 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 42.914 52.733 c -0.552 0 -1 -0.447 -1 -1 V 38.267 c 0 -0.552 0.448 -1 1 -1 h 5.97 c 0.553 0 1 0.448 1 1 s -0.447 1 -1 1 h -4.97 v 12.467 C 43.914 52.286 43.466 52.733 42.914 52.733 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 47.66 46 h -4.747 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 4.747 c 0.553 0 1 0.448 1 1 S 48.213 46 47.66 46 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 60.667 39.267 h -7.653 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 7.653 c 0.553 0 1 0.448 1 1 S 61.22 39.267 60.667 39.267 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 56.841 52.733 c -0.553 0 -1 -0.447 -1 -1 V 38.267 c 0 -0.552 0.447 -1 1 -1 s 1 0.448 1 1 v 13.467 C 57.841 52.286 57.394 52.733 56.841 52.733 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 45 10.816 c -2.982 0 -5.408 -2.426 -5.408 -5.408 S 42.018 0 45 0 c 2.982 0 5.408 2.426 5.408 5.408 S 47.982 10.816 45 10.816 z M 45 2 c -1.879 0 -3.408 1.529 -3.408 3.408 c 0 1.879 1.529 3.408 3.408 3.408 c 1.879 0 3.408 -1.529 3.408 -3.408 C 48.408 3.529 46.879 2 45 2 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 45 90 c -2.982 0 -5.408 -2.426 -5.408 -5.408 s 2.426 -5.408 5.408 -5.408 c 2.982 0 5.408 2.426 5.408 5.408 S 47.982 90 45 90 z M 45 81.184 c -1.879 0 -3.408 1.529 -3.408 3.408 S 43.121 88 45 88 c 1.879 0 3.408 -1.529 3.408 -3.408 S 46.879 81.184 45 81.184 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 8.236 57.007 c -2.982 0 -5.408 -2.426 -5.408 -5.408 s 2.426 -5.408 5.408 -5.408 s 5.408 2.426 5.408 5.408 S 11.218 57.007 8.236 57.007 z M 8.236 48.19 c -1.879 0 -3.408 1.529 -3.408 3.408 s 1.529 3.408 3.408 3.408 c 1.879 0 3.408 -1.529 3.408 -3.408 S 10.115 48.19 8.236 48.19 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 81.764 43.809 c -2.981 0 -5.407 -2.426 -5.407 -5.408 s 2.426 -5.408 5.407 -5.408 c 2.982 0 5.408 2.426 5.408 5.408 S 84.746 43.809 81.764 43.809 z M 81.764 34.993 c -1.879 0 -3.407 1.529 -3.407 3.408 c 0 1.879 1.528 3.408 3.407 3.408 s 3.408 -1.529 3.408 -3.408 C 85.172 36.522 83.643 34.993 81.764 34.993 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <rect x="12.64" y="50.6" rx="0" ry="0" width="11.13" height="2" transform=" matrix(1 0 0 1 0 0) "/>
        <rect x="66.23" y="37.4" rx="0" ry="0" width="11.13" height="2" transform=" matrix(1 0 0 1 0 0) "/>
        <rect x="44" y="9.82" rx="0" ry="0" width="2" height="11.62" transform=" matrix(1 0 0 1 0 0) "/>
        <rect x="44" y="68.57" rx="0" ry="0" width="2" height="11.62" transform=" matrix(1 0 0 1 0 0) "/>
      </g>
    </g>
  </svg>
)

const Cover = ()=> (
  <div className={css.cover}>
    <NFTIcon index={1} />
    <NFTIcon index={2} />
  </div>
)

export default Cover