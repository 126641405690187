import { useEffect }     from 'react'
import Social            from '../../components/Social/Social'
import Particles         from '../../components/Particles/Particles'
import Terminal          from '../../components/Terminal/Terminal'
import TypedContent      from '../../elements/TypedContent/TypedContent'
import css               from './Home.module.css'

const Home = ()=> {

  useEffect(()=> {
    const handler = ()=> {
      if (document.hidden) document.title = 'Hey... Come back!'
      else {
        document.title = 'Thank you!'
        setTimeout(()=> document.title = 'Jorge Aguirre', 1000)
      }
    }
    document.addEventListener('visibilitychange', handler, true)
    return ()=> document.removeEventListener('visibilitychange', handler, true)
  }, [])


  return (
    <div className={css.main}>
      <Particles className={css.particles}/>
      <Terminal className={css.terminal} />
      <div className={css.details}>
        <div className={css.intro}>
          <p className={css.text}>
            I work as a <strong>software developer</strong>
          </p>
          <p className={css.text}>
            alongside tech-driven companies
          </p>
          <p className={css.text}>
            <TypedContent sentences={[
              'developing web applications',
              'with agile methodologies',
              'creating digital solutions',
              'with open source technologies',
              'delivering high quality products',
              'using Artificial Intelligence'
            ]} />
          </p>
        </div>
        <Social className={css.social} />
      </div>
    </div>
  )

}

export default Home