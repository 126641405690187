import React, { Component }                 from 'react'
import interaction                          from './resources/interaction'
import { synthesizeText, recognizeSpeech }  from './resources/voice'
import css                                  from './Terminal.module.css'

const TerminalLink = ({ to, message })=> (
  <a
    className={css.link}
    href={to} 
    target='_blank' 
    rel='noopener noreferrer'
  >
    {message}
  </a>
)

const TerminalLinks = ({ links })=> (
  <>
    {links.map(({ text, link }, index)=> (
      <React.Fragment key={link}>
        <a
          
          className={css.link}
          href={link} 
          rel='noopener noreferrer'
        >
          {text}
        </a>
        {index < (links.length-1) ? ', ' : ''}
      </React.Fragment>
    ))}
  </>
)

class Terminal extends Component {

  state = {
    old_messages: [
      { from: 'jorge', message: 'Welcome to my website!!!!' }
    ],
    textarea: '',
    voice: {
      active: false,
      selectedVoice: ''
    }
  }

  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  componentDidMount() {
    const synth = window.speechSynthesis
    if (synth) {
      const updateVoices = ()=> {
        const voices = synth.getVoices()
          .filter(voice=> voice.lang.startsWith("en"))
          .map(voice=> {
            const { name, default: isDefault } = voice
            return { name, isDefault }
          })
        const selectedVoice = voices.find(voice=> voice.isDefault) || voices[0]
        this.setState(current_state=> ({ ...current_state, voice: { ...current_state.voice, selectedVoice } }))
      }
      synth.onvoiceschanged = updateVoices
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Activate voice support trigger
    if (!prevState.voice.active && this.state.voice.active) {
      const { selectedVoice } = this.state.voice
      if (selectedVoice) {
        this.setState(current_state=> ({ voice: { ...current_state.voice, selectedVoice } }))
        this.pushMessageToTerminal(this.state.textarea, 'Voice interface enabled!')
      }
      else {
        this.setState(current_state=> ({ voice: { ...current_state.voice, active: false } }))
        this.pushMessageToTerminal(this.state.textarea, 'I\'m sorry, voice interface is not supported 👎')
      }
    }
    // If the message stack changes, listen for new instructions
    if (prevState.old_messages.length !== this.state.old_messages.length && this.state.voice.active) {
      recognizeSpeech()
        .then(this.handleInteractionMessage)
        .catch(console.error)
    }
  }

  speakMessage = message=> {
    return new Promise((resolve)=> {
      const { active, selectedVoice } = this.state.voice
      if (active && selectedVoice) synthesizeText(message, selectedVoice).finally(resolve)
      else resolve()
    })
  }

  pushMessageToTerminal = (message, response)=> {
    return this.speakMessage(response)
      .then(()=> {
        this.setState(current_state=> ({
          old_messages: [ 
            ...current_state.old_messages, 
            { from: 'visit', message },
            { from: 'jorge', message: response } 
          ],
          textarea: ''
        }))
      })
  }

  handleInteractionMessage = message=> {
    const response = interaction(message)
    if (!response.special) return this.pushMessageToTerminal(message, response.message)
    // Special commands 'clear', 'voice', 'link'
    if (response.action === 'clear') {
      this.speakMessage('The terminal has been cleared')
        .then(()=> {
          this.setState(current_state=> ({
            old_messages: [ current_state.old_messages[0] ],
            textarea: ''
          }))
        })
    }
    if (response.action === 'voice') {
      this.setState(current_state=> ({ voice: { ...current_state.voice, active: true } }))
    }
    if (response.action === 'voice-disable') {
      this.pushMessageToTerminal(message, 'Voice interface has been disabled')
      this.setState(current_state=> ({ voice: { ...current_state.voice, active: false } }))
    }
    if (response.action === 'link') {
      this.pushMessageToTerminal(message, <TerminalLink {...response} />)
    }
    if (response.action === 'links') {
      this.pushMessageToTerminal(message, <TerminalLinks {...response} />)
    }
  }

  handleTerminalClick = ()=> this.input.current.focus()

  handleTextareaUpdate = e=> this.setState({textarea: e.target.value.replace('\n', ' ').substring(0, 100)})

  handleTextareaKeyPress = e=> {
    if (e.key === 'Enter') {
      e.preventDefault()
      const current_message = this.state.textarea.trim().toLowerCase()
      this.handleInteractionMessage(current_message)
    }
  }

  render() {
    return (
      <div
        onClick={this.handleTerminalClick}
        className={`${css.terminal} ${this.props.className}`}
      >
        <div className={css.bar}>
          <div className={css['bar--red']} />
          <div className={css['bar--yellow']} />
          <div className={css['bar--green']} />
        </div>
        <div className={css.screen}>
          {this.state.old_messages.map((message, i)=> (
            <p key={i} className={`${css.content} ${css[`content--${message.from}`]}`}>
              <strong>{message.from} &gt;</strong> {message.message}
            </p>
          ))}
          <p className={css.content}>
            <strong>visit &gt; </strong>
            <textarea
              ref={this.input}
              autoCorrect='off'
              autoComplete='off'
              autoCapitalize='off'
              spellCheck='false'
              className={css.input}
              type='text'
              value={this.state.textarea}
              onChange={this.handleTextareaUpdate}
              onKeyPress={this.handleTextareaKeyPress}
            />
          </p>
        </div>
      </div>
    )
  }

}

export default Terminal
