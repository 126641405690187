import { useState }         from 'react'
import GameHeader           from '../../components/GameHeader/GameHeader'
import * as MastermindUtils from './resources/utils'
import css                  from './Mastermind.module.css'

const noop = ()=> {}

const ArrowIcon = ({ className })=> (
  <svg className={className} viewBox="0 0 24 24">
    <path d="M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z"/>
  </svg>
)

const Evaluation = ({ attempt, solution })=> {
  const evaluation = MastermindUtils.evaluateAttempt({ attempt, solution })
  const { correct, misplaced, incorrect } = evaluation
  return (
    <div className={css.evaluation}>
      {Array(correct)
        .fill('correct')
        .map((status, index)=> (
          <EvaluationPiece key={`${status}-${index}`} status={status} />
        )
      )}      
      {Array(misplaced)
        .fill('misplaced')
        .map((status, index)=> (
          <EvaluationPiece key={`${status}-${index}`} status={status} />
        )
      )}
      {Array(incorrect)
        .fill('incorrect')
        .map((status, index)=> (
          <EvaluationPiece key={`${status}-${index}`} status={status} />
        )
      )}
    </div>
  )
}

const Row = ({ row=[], isInteractive, onPieceClick, onPieceDrag, onPieceDrop })=> {
  const attempt = Array(4).fill(null).map((_value, i)=> row[i] || null)
  return (
    <div className={css.row}>
      {attempt.map((color, i)=> (
        <GamePiece 
          key={i} 
          color={color} 
          isInteractive={isInteractive}
          isDroppable={isInteractive}
          isDraggable={isInteractive}
          onDoubleClick={()=> onPieceClick(i)}
          onDrop={()=> onPieceDrop(i)}
          onDrag={()=> onPieceDrag(i)}
        />
      ))}
    </div>
  )
}

const PieceStack = ({ attempt, dragSource, isInteractive, onPieceClick, onPieceDrag, onPieceDrop })=> {
  const handlePieceClick = color=> ()=> onPieceClick(color)
  const handlePieceDrag = color=> ()=> onPieceDrag(color)
  const getColor = (color)=> attempt.includes(color) ? null : color
  return (
    <div className={css.pieceStack}>
      {MastermindUtils.COLORS
        .map((color)=> (
          <GamePiece 
            key={color} 
            color={getColor(color)} 
            onDoubleClick={handlePieceClick(color)} 
            onDrag={handlePieceDrag(color)}
            onDrop={onPieceDrop}
            isInteractive={isInteractive}
            isDraggable={isInteractive}
            isDroppable={dragSource === 'attempt' && isInteractive}
          />
        ))
      }
    </div>
  )
}

const EvaluationPiece = ({ status='' })=> (
  <div className={css.evaluationPiece} data-status={status} />
)

const GamePiece = ({ 
  color, 
  isInteractive, 
  isDraggable, 
  isDroppable,
  onDoubleClick, 
  onDrag=noop,
  onDrop=noop,
})=> {

  const [isDragging, setDragging] = useState(false)

  const handleDragOver = event=> {
    if (isDroppable) {
      setDragging(true)
      event.preventDefault()
    }
  }

  const handleDragLeave = ()=> {
    setDragging(false)
  }

  const handleDrop = ()=> {
    setDragging(false)
    onDrop()
  }

  const handleClick = (event)=> {
    const detail = event.detail
    if (detail === 2) onDoubleClick()
  }

  if (!color) return (
    <div
      className={css.piece} 
      data-status='empty' 
      data-action={isInteractive ? 'interactive' : 'static'}
      data-drag-status={isDragging ? 'dragging' : 'normal'}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    />
  )
  return (
    <div
      className={css.piece} 
      draggable={isDraggable}
      data-action={isInteractive ? 'interactive' : 'static'}
      data-drag-status={isDragging ? 'dragging' : 'normal'}
      data-color={color} 
      onClick={handleClick}
      onDrag={onDrag}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    />
  )
}

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      The goal is to guess the pattern randomly selected by the computer.
    </p>
    <p className={css.rulesBlock}>
      There are six colors available, colors are not repeated.
    </p>
    <p className={css.rulesBlock}>
      The computer will give you feedback on the number of correct colors and positions.
      A yellow pin indicates that a color is present but not in the correct position.
      A green pin indicates that the color is in the correct position.
    </p>
    <p>
      Double click on a piece from the box to add it to your current attempt.
      <br />
      Double click on a piece from your current attempt to remove it.
    </p>
  </>
)

const Mastermind = ()=> {

  const [pattern, setPattern] = useState(MastermindUtils.generateRandomPattern())
  const [hint, setHint] = useState(MastermindUtils.getRandomHint())

  const [attempts, setAttempts] = useState([])
  const [attempt, setAttempt] = useState([])
  const [success, setSuccess] = useState(false)

  const [dragColor, setDragColor] = useState({ color: null, source: null })


  const handleResetBoard = ()=> {
    setPattern(MastermindUtils.generateRandomPattern())
    setAttempts([])
    setAttempt([])
    setHint(MastermindUtils.getRandomHint())
    setSuccess(false)
  }

  const isValidAttempt = ()=> attempt.length === 4 && attempt.every(color=> color)

  const handleAttempt = ()=> {
    setAttempts([...attempts, attempt])
    const evaluation = MastermindUtils.evaluateAttempt({ attempt, solution: pattern })
    if (evaluation.correct === 4) setSuccess(true)
    setHint(MastermindUtils.getRandomHint())
    setAttempt([])
  }

  const handleRemovePiece = i=> {
    const newAttempt = [...attempt]
    newAttempt.splice(i, 1)
    setAttempt(newAttempt)
  }

  const handleAddPiece = color=> {
    if (attempt.length === 4) return
    const newAttempt = [...attempt, color]
    setAttempt(newAttempt)
  }

  const handleDragPieceFromBox = color=> setDragColor({ color, source: 'box' })
  const handleDragPieceFromAttempt = i=> setDragColor({ color: attempt[i], source: 'attempt' })

  const handleDropPieceInAttempt = position=> {
    const { color, source } = dragColor
    if (source === 'box') {
      const newAttempt = [...attempt]
      newAttempt[position] = color
      setAttempt(newAttempt)
      setDragColor({ color: null, source: null })
    }
    if (source === 'attempt') {
      const sourcePosition = attempt.indexOf(color)
      if (position === sourcePosition) return
      const newAttempt = attempt.map((currentColor, i)=> {
        if (i === sourcePosition) return attempt[position]
        if (i === position) return color
        return currentColor
      })
      setAttempt(newAttempt)
      setDragColor({ color: null, source: null })
    }
  }

  const handleDropPieceInBox = ()=> {
    const { color, source } = dragColor
    if (source === 'box') return
    if (source === 'attempt') {
      const newAttempt = attempt.filter(currentColor=> currentColor !== color)
      setAttempt(newAttempt)
      setDragColor({ color: null, source: null })
    }
  }

  return (
    <>
      <GameHeader 
        title='Mastermind' 
        rules={<GameRules />}
        options={[]}
        onReset={handleResetBoard}
      />
      <div className={css.main} data-drag-active>
        <div className={css.board}>
          {attempts.map((attempt, i)=> (
            <div className={css.history} key={i}>
              <Row row={attempt} onPieceClick={noop} />
              <Evaluation attempt={attempt} solution={pattern}  />
            </div>
          ))}
          {!success && (
            <div className={css.message}>
              <p className={css.hint}>
                {attempts.length === 0 ? 'Make your first guess' : hint}
              </p> 
            </div>
          )}
          {success && (
            <div className={css.message}>
              <p className={css.successMessage}>
                You did it!
              </p> 
            </div>
          )}
        </div>
        <div className={css.attempt}>
          <Row 
            isInteractive={!success}
            row={attempt} 
            onPieceClick={handleRemovePiece} 
            onPieceDrag={handleDragPieceFromAttempt}
            onPieceDrop={handleDropPieceInAttempt}
          />
          <div className={css.buttonContainer}>
            <button 
              className={css.button}
              disabled={!isValidAttempt()}
              onClick={handleAttempt}
            >
              <ArrowIcon className={css.icon} />
            </button>
          </div>
        </div>
        <PieceStack
          isInteractive={!success} 
          attempt={attempt} 
          dragSource={dragColor.source}
          onPieceClick={handleAddPiece}
          onPieceDrag={handleDragPieceFromBox}
          onPieceDrop={handleDropPieceInBox}
        />
      </div>
    </>
  )

}

export default Mastermind