import ConceptHeader         from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts        from '../../components/BackToConcepts/BackToConcepts'
import Whiteboard            from './resources/Whiteboard'
import CodeArea              from '../../components/CodeArea/CodeArea'
import { CodeLine }          from '../../components/CodeArea/CodeArea'
import css                   from './DrawingCanvas.module.css'

const TutorialLink = ()=> (
  <a 
    className={css.link} 
    href='https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API'
  >
    Canvas API Tutorial
  </a>
)

const DrawingCanvas = ()=> (
  <>
    <ConceptHeader title='Drawing in a canvas' />
    <div className={css.main}>
      <Whiteboard />
      <p className={css.block}>
        The <code>&lt;canvas&gt;</code> is a HTML tag that is used for drawing graphics through <code>JavaScript</code>.
        It has a lot of possibilities for drawing and animating shapes, images, and lines. There is a very decent tutorial
        that outlines some of the possibilities of this element in MDN: <TutorialLink />
      </p>
      <p className={css.block}>
        In this case, we've used <strong>hooks</strong> to capture mouse events in the canvas 
        (<code>mousedown</code>, <code>mousemove</code>, <code>mouseout</code>...) and update the canvas accordingly. As a
        result, everytime the user drags the mouse around the canvas, a line is drawn following the mouse, much like in a 
        school's blackboard (which was the inspiration for this component).
      </p>
      <p className={css.block}>
        This particular canvas contains a few tiny extras, like the eraser button (right of the color palette), the clear button 
        (which simply calls <code>clearRect</code> on the canvas), and the download button, which creates a JPG image of the canvas
        and then downloads it to the user's device. However, I'm only going to go over the method for freestyle drawing in the canvas.
      </p>
      <p className={css.block}>
        I'm using a few <code>useState</code> to store details about the canvas, but the only relevant for drawing freestyle are:
      </p>
      <CodeArea>
        <CodeLine fragments={[
          { content: 'const', color: 'orange' }, 
          { content: ' [', color: 'orange' }, 
          { content: 'brush', color: 'violet' }, 
          { content: ', ' }, 
          { content: 'setBrush', color: 'violet' }, 
          { content: ']', color: 'orange' }, 
          { content: ' = ' }, 
          { content: 'useState', color: 'blue' }, 
          { content: '(', color: 'yellow' }, 
          { content: '{ ', color: 'pink' }, 
          { content: 'color', color: 'wood' }, 
          { content: ':' }, 
          { content: ' "#f2f2f2"', color: 'green' }, 
          { content: ' }', color: 'pink' }, 
          { content: ')', color: 'yellow' }, 
        ]} />
        <CodeLine fragments={[
          { content: 'const', color: 'orange' }, 
          { content: ' [', color: 'orange' }, 
          { content: 'position', color: 'violet' }, 
          { content: ', ' }, 
          { content: 'setPosition', color: 'violet' }, 
          { content: ']', color: 'orange' }, 
          { content: ' = ' }, 
          { content: 'useState', color: 'blue' }, 
          { content: '(', color: 'yellow' }, 
          { content: '{ ', color: 'pink' }, 
          { content: 'x', color: 'wood' }, 
          { content: ':' }, 
          { content: ' 0', color: 'red' }, 
          { content: ', ' }, 
          { content: 'y', color: 'wood' }, 
          { content: ':' }, 
          { content: ' 0', color: 'red' }, 
          { content: ' }', color: 'pink' }, 
          { content: ')', color: 'yellow' }, 
        ]} />
      </CodeArea>
      <p className={css.block}>
        Then, when the user moves through the canvas element, I capture the move event, and draw a line in the canvas. It's important 
        to keep track of the previous mouse position, hence, the call to <code>setPosition</code>.
      </p>
      <p className={css.block}>
        The listener extracts the position of the move event, and then calculates where within the canvas the mouse was 
        moved to. That information, combined with the previous mouse position (which have we conveniently set in 
        a <code>useState</code> hook), is sufficient to draw a line in the canvas following the mouse. I've also saved the 
        color in the state, so we have to set that property as well, before actually drawing.
      </p>
      <p className={css.block}>
        After drawing, it's important to update the position of the mouse, so that the next line to be drawn starts where the 
        current line finishes.
      </p>
      <CodeArea>
          <CodeLine fragments={[
            { content: 'const ', color: 'orange' }, 
            { content: 'handleMouseMove', color: 'blue' }, 
            { content: ' = '},
            { content: 'e', color: 'wood'},
            { content: '=> {', color: 'red'},
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' }, 
            { content: '{ ', color: 'orange' }, 
            { content: 'clientX', color: 'blue-2' },
            { content: ', ', color: 'yellow'},
            { content: 'clientY ', color: 'blue-2' },
            { content: '}', color: 'orange'},
            { content: ' = '},
            { content: 'e', color: 'wood'},
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' }, 
            { content: '{ ', color: 'orange' }, 
            { content: 'x', color: 'blue-2' },
            { content: ', ', color: 'yellow'},
            { content: 'y ', color: 'blue-2' },
            { content: '}', color: 'orange'},
            { content: ' = '},
            { content: 'canvas', color: 'blue'},
            { content: '.' },
            { content: 'getBoundingClientRect()', color: 'pink'},
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' }, 
            { content: 'endX', color: 'blue-2' }, 
            { content: ' = ' }, 
            { content: 'clientX', color: 'blue-2' }, 
            { content: ' - ' }, 
            { content: 'x', color: 'blue-2' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' }, 
            { content: 'endY', color: 'blue-2' }, 
            { content: ' = ' }, 
            { content: 'clientY', color: 'blue-2' }, 
            { content: ' - ' }, 
            { content: 'y', color: 'blue-2' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' }, 
            { content: 'context', color: 'yellow' }, 
            { content: ' = ' }, 
            { content: 'canvas', color: 'blue' }, 
            { content: '.' }, 
            { content: 'getContext(', color: 'pink' }, 
            { content: '"2d"', color: 'green' }, 
            { content: ')', color: 'pink' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'context', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'beginPath()', color: 'pink' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'context', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'moveTo(', color: 'pink' }, 
            { content: 'position', color: 'violet' }, 
            { content: '.' }, 
            { content: 'x', color: 'violet-2' }, 
            { content: ', ' }, 
            { content: 'position', color: 'violet' }, 
            { content: '.' }, 
            { content: 'y', color: 'violet-2' }, 
            { content: ')', color: 'pink' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'context', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'lineTo(', color: 'pink' }, 
            { content: 'endX', color: 'blue-2' }, 
            { content: ', ' }, 
            { content: 'endY', color: 'blue-2' }, 
            { content: ')', color: 'pink' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'context', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'globalCompositeOperation', color: 'pink' }, 
            { content: ' = ' }, 
            { content: '"source-over"', color: 'green' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'context', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'strokeStyle', color: 'pink' }, 
            { content: ' = ' }, 
            { content: 'brush', color: 'violet' },
            { content: '.' },
            { content: 'color', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'context', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'stroke()', color: 'pink' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'context', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'closePath()', color: 'pink' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'setPosition(', color: 'violet' }, 
            { content: '{ ', color: 'yellow' }, 
            { content: 'x', color: 'wood' }, 
            { content: ': ' }, 
            { content: 'endX', color: 'blue-2' }, 
            { content: ', ' }, 
            { content: 'y', color: 'wood' }, 
            { content: ': ' }, 
            { content: 'endY ', color: 'blue-2' }, 
            { content: '}', color: 'yellow' }, 
            { content: ')', color: 'violet' }, 
          ]} />
          <CodeLine fragments={[
            { content: '} ', color: 'red' }, 
          ]} />
        </CodeArea>
        <p className={css.block}>
          Note that the move event fires <strong>way too often</strong> and, since we are both drawing and updating React's state, 
          it's probably wise to add a bit of <strong>throttling</strong> to limit the number of times we capture the event. 
        </p>
        <p className={css.block}>
          Also, I use the <code>onMouseDown</code> and the <code>onMouseUp</code> and similar events to activate and deactivate the
          drawing. I've omitted this from the code fragment above, but it's basically an on/off switch, so that unless you are actively
          pressing the mouse, nothing gets drawn.
        </p>
      </div>
    <BackToConcepts />
  </>
)

export default DrawingCanvas