import ConceptHeader        from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts       from '../../components/BackToConcepts/BackToConcepts'
import css                  from './ArtASCII.module.css'

const first_name = `
       ___         ___           ___           ___           ___
      /\\  \\       /\\  \\         /\\  \\         /\\  \\         /\\  \\
      \\:\\  \\     /::\\  \\       /::\\  \\       /::\\  \\       /::\\  \\
  ___ /::\\__\\   /:/\\:\\  \\     /:/\\:\\  \\     /:/\\:\\  \\     /:/\\:\\  \\
 /\\  /:/\\/__/  /:/  \\:\\  \\   /::\\~\\:\\  \\   /:/  \\:\\  \\   /::\\~\\:\\  \\
 \\:\\/:/  /    /:/__/ \\:\\__\\ /:/\\:\\ \\:\\__\\ /:/__/_\\:\\__\\ /:/\\:\\ \\:\\__\\
  \\::/  /     \\:\\  \\ /:/  / \\/_|::\\/:/  / \\:\\  /\\ \\/__/ \\:\\~\\:\\ \\/__/
   \\/__/       \\:\\  /:/  /     |:|::/  /   \\:\\ \\:\\__\\    \\:\\ \\:\\__\\
                \\:\\/:/  /      |:|\\/__/     \\:\\/:/  /     \\:\\ \\/__/
                 \\::/  /       |:|  |        \\::/  /       \\:\\__\\
                  \\/__/         \\|__|         \\/__/         \\/__/
`
const last_name = `
       ___           ___           ___                       ___           ___           ___
      /\\  \\         /\\  \\         /\\__\\          ___        /\\  \\         /\\  \\         /\\  \\
     /::\\  \\       /::\\  \\       /:/  /         /\\  \\      /::\\  \\       /::\\  \\       /::\\  \\
    /:/\\:\\  \\     /:/\\:\\  \\     /:/  /          \\:\\  \\    /:/\\:\\  \\     /:/\\:\\  \\     /:/\\:\\  \\
   /::\\~\\:\\  \\   /:/  \\:\\  \\   /:/  /  ___      /::\\__\\  /::\\~\\:\\  \\   /::\\~\\:\\  \\   /::\\~\\:\\  \\
  /:/\\:\\ \\:\\__\\ /:/__/_\\:\\__\\ /:/__/  /\\__\\  __/:/\\/__/ /:/\\:\\ \\:\\__\\ /:/\\:\\ \\:\\__\\ /:/\\:\\ \\:\\__\\
  \\/__\\:\\/:/  / \\:\\  /\\ \\/__/ \\:\\  \\ /:/  / /\\/:/  /    \\/_|::\\/:/  / \\/_|::\\/:/  / \\:\\~\\:\\ \\/__/
       \\::/  /   \\:\\ \\:\\__\\    \\:\\  /:/  /  \\::/__/        |:|::/  /     |:|::/  /   \\:\\ \\:\\__\\
       /:/  /     \\:\\/:/  /     \\:\\/:/  /    \\:\\__\\        |:|\\/__/      |:|\\/__/     \\:\\ \\/__/
      /:/  /       \\::/  /       \\::/  /      \\/__/        |:|  |        |:|  |        \\:\\__\\
      \\/__/         \\/__/         \\/__/                     \\|__|         \\|__|         \\/__/
`

export const MonospaceRenderer = ({ className, text })=> (
  <p className={className}>
    {text.split("").map((char, index) => {
      if (char === '\n') return <br key={index} />
      else if (char === ' ') return <span key={index}>&nbsp;</span>
      else return <span key={index}>{char}</span>
    })}
  </p>
)

const ArtASCII = ()=> (
  <>
    <ConceptHeader title='ASCII art with Jorge Aguirre' />
      <div className={css.main}>
        <p className={css.block}>
          So this is apparently a thing (and a rather common one, just Google it and you will find plenty of results). And I
          actually kinda like it. I don't actually like it enough to put it as my email signature, but it's still sweet.
          If you are like me, and are trying to render this thing in <code>React</code>, there are just a couple of tweaks to 
          get it working.
        </p>
        <div className={css.art}>
          <MonospaceRenderer className={css.signature} text={first_name} />
          <MonospaceRenderer className={css.signature} text={last_name} />
        </div>
        <p className={css.block}>
          First, make sure you are using a <strong>monospace</strong> font, otherwise, it will get messed up real quick.
          These kind of fonts enforce a unique character width. That way, two texts of the same length have the exact same with, 
          regardless of their content. It's pretty useful for making drawings out of characters, otherwise it would just be chaos.
        </p>
        <p className={css.block}>
          The second thing, although depending on how you approach this, you may not run into this problem, is to properly render 
          whitespace characters. The key here is substituting them for <code>&amp;nbsp;</code>. It's a special whitespace character 
          that will effectively be a whitespace, but won't be removed by JS.
        </p>
      </div>
    <BackToConcepts />
  </>
)

export default ArtASCII