import css from './Slider.module.css'

const noop = ()=> {}

const Slider = ({value, onChange=noop, min=0, max=100})=> (
  <input
    className={css.slider}
    type='range'
    min={min}
    max={max}
    value={value}
    onChange={e=> onChange(e.target.value)}
    onInput={e=> onChange(e.target.value)}
    onMouseUp={e=> e.target.blur()}
  />
)

export default Slider