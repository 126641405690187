import { formatNumber } from '../numbers'
import css              from './ResultPanel.module.css'

const LinesOfCodeClick = ()=> (
  <button className={css.button}>
    <svg className={css.icon} viewBox="0 0 24 24">
      <path d="M22 3.2c0-.663-.537-1.2-1.2-1.2h-17.6c-.663 0-1.2.537-1.2 1.2v11.8h20v-11.8zm-2 9.8h-16v-9h16v9zm2 3h-20c-.197.372-2 4.582-2 4.998 0 .522.418 1.002 1.002 1.002h21.996c.584 0 1.002-.48 1.002-1.002 0-.416-1.803-4.626-2-4.998zm-12.229 5l.467-1h3.523l.467 1h-4.457z"/>
    </svg>
  </button>
)

const ResultPanel = ({ lines, efficiency, onClick })=> (
  <div className={css.main}>
    <p className={css.total}>
      Lines of code:{' '}
      <strong>{formatNumber(Math.floor(lines))}</strong>
    </p>
    <LinesOfCodeClick />
    <p className={css.total}>
      Code production:{' '}
      <strong>{formatNumber(Math.floor(efficiency))} λ/s</strong>
    </p>
  </div>
)

export default ResultPanel