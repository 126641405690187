import { useState }           from 'react'
import CodeArea               from '../../components/CodeArea/CodeArea'
import { CodeLine }           from '../../components/CodeArea/CodeArea'
import { FragmentUtils }      from '../../components/CodeArea/CodeArea'
import ConceptHeader          from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts         from '../../components/BackToConcepts/BackToConcepts'
import Slider                 from '../../elements/Slider/Slider'
import css                    from './SliderImageTransition.module.css'

const SliderImageTransition = ()=> {

  const [value, setValue] = useState(50)
  const [sample, setSample] = useState(250)

  return (
    <>
      <ConceptHeader title='Using a slider to generate a smooth image transition' />
      <div className={css.main}>
        <p className={css.block}>
          Sliders are not widely supported yet. Depending on your browser, you
          may see a fallback for the slider which a lot less cool. It's possible
          to use a custom element for the slider, or something different
          altogether (like detecting <code>mouseMove</code>) but, the slider
          is easy to understand and visually powerful for this example:
        </p>
        <div className={css.sample}>
          <div className={css.container}>
            <div
              className={css.image}
              data-position='top'
              style={{width: `${value/8}%`}}
            />
            <div 
              className={css.image} 
              data-position='bottom'
            />
          </div>
          <Slider
            min={0}
            max={800}
            value={value}
            onChange={setValue}
          />
        </div>
        <p className={css.block}>
          To accomplish this, there is a bit of CSS and a bit of JS. I'm not
          going to go over the slider in detail, since support is not wide yet.
          Also because there is <strong>a lot</strong> of CSS involved. For this
          example, you need something similar to the code below. Please note 
          I've used React, and thus, everything is a little different since 
          it uses <code>JSX</code>.
        </p>
        <CodeArea>
          <CodeLine fragments={FragmentUtils.HTML.tag('div', [{key: 'class', value: 'image-container'}])} />
          <CodeLine indent={1} fragments={FragmentUtils.HTML.closedTag('div', [{key: 'class', value: 'image image--top'}])} />
          <CodeLine indent={1} fragments={FragmentUtils.HTML.closedTag('div', [{key: 'class', value: 'image image--bottom'}])} />
          <CodeLine indent={1} fragments={FragmentUtils.HTML.tag('input', [{key: 'type', value: 'range'}, {key: 'min', value: '0'}, {key: 'max', value: '100'}])} />
          <CodeLine fragments={FragmentUtils.HTML.tagClose('div')} />
        </CodeArea>
        <p className={css.block}>
          The HTML is pretty simple. Each <code>{'<div/>'}</code> will contain
          an image. One will always be visible, and the other one will be
          overlapping, but partially hidden. The slider will be used to control
          how much of the overlapped image is visible (that part requires a bit
          of JavaScript).
        </p>
        <CodeArea>
          <CodeLine fragments={[{ content: '.image-container', color: 'orange', modifiers: 'strong' }, { content: ' {' } ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('position', 'relative')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('width', '100%')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('height', '500px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('margin-bottom', '20px')} />
          <CodeLine content='}' />
          <CodeLine />
          <CodeLine fragments={[{ content: '.image', color: 'orange', modifiers: 'strong' }, { content: ' {' } ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('height', '100%')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('position', 'absolute')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('top', '0')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('background-repeat', 'no-repeat')} />
          <CodeLine content='}' />
          <CodeLine />
          <CodeLine fragments={[{ content: '.image--top', color: 'orange', modifiers: 'strong' }, { content: ' {' } ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('z-index', '2')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('background', '<top-image-url>')} />
          <CodeLine content='}' />
          <CodeLine />
          <CodeLine fragments={[{ content: '.image--bottom', color: 'orange', modifiers: 'strong' }, { content: ' {' } ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('z-index', '1')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('background', '<bottom-image-url>')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('width', '100%')} />
          <CodeLine content='}' />
          <CodeLine />
        </CodeArea>
        <p className={css.block}>
          The CSS does most of the work, setting both images on the container
          using absolute positioning. It also uses z-indexing to place one on top
          of the other. The width of the bottom image is fixed (as you can see in
          the CSS) and it's set to fill the container. The top image, on the other
          hand does not have a fixed width. We will be controlling it using the
          slider, so it does not make much sense to give it a value in CSS.
        </p>
        <p className={css.block}>
          Now, there is a bit of JS magic pending. For this to work you need to
          add an event listener to the slider, to detect changes. The event
          listener must set the width of the top image appropiately. The slider we
          have created can have any value between 0 and 500.
        </p>
        <p className={css.block}>
          The recommended approach here is to take that value and divide it by
          5 (making it a value between 0 and 100). The resulting number can be
          then used to set the width of the top image (in percentage).
        </p>
        <div className={css.detail}>
          <div className={css.value}>
            Slider: <strong>{sample}</strong>
          </div>
          <div className={css.percentage}>
            Width: <strong>{sample/5}%</strong>
          </div>
        </div>
        <Slider
          min={0}
          max={500}
          value={sample}
          onChange={setSample}
        />
        <p className={css.block}>
          For the example shown here, I've used the same image twice, but the
          bottom image has a grayscale filer applied, and the top image does
          not. That way, moving the slider creates the illusion of colouring
          and decolouring the image.
        </p>
      </div>
      <BackToConcepts />
    </>
  )
}

export default SliderImageTransition