import css from './Cover.module.css'

const Cover = ()=> (
  <div className={css.cover}>
    <div className={css.portal} data-portal='1' />
    <div className={css.portal} data-portal='2' />
    <div className={css.area}>
      <div className={css.ball} />
    </div>
  </div>
)

export default Cover