import { useEffect, useState }  from 'react'
import GameHeader               from '../../components/GameHeader/GameHeader'
import { shuffle }              from '../../utils/math'
import MemoryCard               from './resources/MemoryCard'
import css                      from './Memory.module.css'

const GameStatus = {
  IDLE: 'idle',
  PLAYING: 'playing',
  ENDED: 'ended'
}

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      Memory is about finding the card pairs.
    </p>
    <p className={css.rulesBlock}>
      When you select two different cards from the board, they will be flipped.
      If their drawing matches, they will be removed from the board, if not, they will
      be flipped down again. Remembering the position of each drawing will help you get a higher
      score.
    </p>
    <p className={css.rulesBlock}>
      The score will be the time it takes you to clear all the cards from the board and the number of
      cards you flip!
    </p>
  </>
)

const generateDeck = ()=> {
  const cards = [ 'car', 'watermelon', 'bishop', 'flower', 'mountains', 'pig', 'hammer', 'waves', 'coffee', 'compass' ]
  const deck = [...cards, ...cards]
  return shuffle(deck)
}

const Memory = ()=> {

  const [deck, setDeck] = useState(generateDeck())
  const [gameStatus, setGameStatus] = useState(GameStatus.PLAYING)
  const [gameTime, setGameTime] = useState(0)
  const [gameAttempts, setGameAttempts] = useState(0)
  const [choice, setChoice] = useState([])
  const [isFlipping, setFlipping] = useState(false)

  useEffect(()=> {
    let isCurrent = true
    if (choice.length === 2) {
      setTimeout(()=> {
        if (isCurrent) setFlipping(true)
      }, 300)
    }
    return ()=> isCurrent = false
  }, [choice])

  useEffect(()=> {
    if (!isFlipping) return
    let isCurrent = true
    setTimeout(()=> {
      if (!isCurrent) return
      if (deck[choice[0]] === deck[choice[1]]) {
        setDeck(current_deck=> current_deck.map((card, index)=> {
          if (index === choice[0] || index === choice[1]) return null
          return card
        }))
      }
      setGameAttempts(current_attempts=> Math.min(999, current_attempts+1))
      setFlipping(false)
      setChoice([])
    }, 1000)
    return ()=> isCurrent = false
  }, [isFlipping, deck, choice])


  useEffect(()=> {
    if (deck.filter(c=> c !== null).length) return
    setGameStatus(GameStatus.ENDED)
    setDeck(generateDeck())
    setChoice([])
    setFlipping(false)
  }, [deck])

  useEffect(()=> {
    const interval = setInterval(()=> {
      if (gameStatus === GameStatus.PLAYING) setGameTime(current=> Math.min(999, current+1))
    }, 1000)
    return ()=> clearInterval(interval)
  }, [gameStatus])

  const handleCardClick = index=> {
    if (gameStatus !== GameStatus.PLAYING) return
    if (isFlipping || choice.length === 2) return
    setChoice(current_choice=> {
      if (current_choice.length === 2) return current_choice
      if (current_choice.includes(index)) return current_choice.filter(e=> e !== index)
      else return [...current_choice, index]
    })
  }

  const handleStartGame = ()=> {
    setDeck(generateDeck())
    setGameStatus(GameStatus.PLAYING)
    setGameTime(0)
    setGameAttempts(0)
    setChoice([])
    setFlipping(false)
  }

  return (
    <>
      <GameHeader 
        title='The memory game' 
        rules={<GameRules />}
        options={[]}
        onReset={handleStartGame}
      />
      <div className={css.main}>

      <div className={css.controls}>
          <p className={css.title}>
            {gameStatus === GameStatus.IDLE && 'Current score'}
            {gameStatus === GameStatus.PLAYING && 'Current score'}
            {gameStatus === GameStatus.ENDED && 'Final score'}
          </p>
          <div className={css.panel}>
            <div className={css.timer}>{gameTime}</div>
            <div className={css.attempts}>{gameAttempts}</div>
          </div>
        </div>
        <div data-status={gameStatus} className={css.board}>
          {deck.map((card, index)=> (
            <div className={css.square} key={index}>
              {card && (
                <div 
                  className={css.card} 
                  data-status={choice.includes(index) ? 'selected' : 'unselected'}
                  onClick={()=> handleCardClick(index)}
                >
                  <MemoryCard card={(isFlipping && choice.includes(index)) ? card : 'unknown'} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Memory