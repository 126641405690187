import { useState }  from 'react'
import { Link }      from 'react-router-dom'
import css           from './ContactForm.module.css'

const ContactForm = ({ email, message, status, onChange, onSubmit })=> {

  const [jorchVisible, setJorchVisible] = useState(false)

  const Jorch = ()=> (
    <span 
      onClick={()=> setJorchVisible(!jorchVisible)}
      className={css.interactive}
    >
      J.O.R.C.H.
    </span>
  )

  const Terms = ()=> (
    <Link
      className={css.link}
      to='/legal'
    >
      terms and conditions
    </Link>
  )

  return (
    <form className={css.main} onSubmit={onSubmit}>
      <p className={css.text}>Fellow human,</p>
      <p className={css.text}>
        Other humans have labeled me <Jorch />{' '}
        {jorchVisible && (
          <span className={css.detail}>
            <strong>J</strong>orge's{' '}
            <strong>O</strong>rganizational{' '}
            <strong>R</strong>obot &{' '}
            <strong>C</strong>ommunications{' '}
            <strong>H</strong>andler
          </span>
        )}
      </p>
      <p className={css.text}>This is acceptable.</p>
      <p className={css.text}>
        I will attempt to deliver the data below, provided you agree to the <Terms />.
      </p>
      <div className={css.block}>
        <input 
          required
          type='email'
          className={css.input}
          value={email}
          placeholder='<your-email>'
          onChange={e=> onChange('email', e.target.value)}
        />
      </div>
      <div className={css.block}>
        <textarea 
          required
          minLength='20'
          className={css.input}
          value={message}
          placeholder='<your-message>'
          onChange={e=> onChange('message', e.target.value)}
        />
      </div>
      {status === 'idle' && <button type='submit' className={css.submit}>Deliver</button>}
    </form>
  )
}

export default ContactForm