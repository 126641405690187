import { useCallback, useState, useEffect }   from 'react'
import GameHeader                             from '../../components/GameHeader/GameHeader'
import words                                  from './resources/words.json'
import sharing                                from './resources/sharing.json'
import { getAttemptStatus, getKeyStatus }     from './resources/utils'
import Modal                                  from '../../components/Modal/Modal'
import css                                    from './Wordle.module.css'

const TOTAL_ATTEMPTS = 6

const KEYBOARD = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ'],
  ['enter', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'delete']
]

const noop = ()=> {}

const generateRandomWord = ()=> {
  const randomIndex = Math.floor(Math.random() * words.length)
  return words[randomIndex]
}

const DeleteIcon = ()=> (
  <svg className={css.icon} viewBox="0 0 24 24">
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2" 
      d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" 
    />
  </svg>
)

const Letter = ({ status='unknown', letter='' })=> {
  return (
    <div data-status={status} className={css.letter}>
      <span className={css.letterContent}>{letter}</span>
    </div>
  )
}

const Attempt = ({ attempt, solution })=> {
  const status = getAttemptStatus({ attempt, word: solution })
  return (
    <div className={css.attempt}>
      {attempt
        .split('')
        .map((letter, i)=> (
          <Letter key={i} status={status[i]} letter={letter} />
        )
      )}
    </div>
  )
}

const Attempts = ({ currentAttempt, attempts, solution, shake=false })=> {
  
  const remainingAttempts = TOTAL_ATTEMPTS - attempts.length
  return (
    <div className={css.attempts}>
      {attempts.map((attempt, i)=> (
        <Attempt key={i} attempt={attempt} solution={solution} />
      ))}
      {(remainingAttempts > 0) && (
        <div data-vibration={shake ? 'shake' : 'normal'} className={css.attempt}>
          {`${currentAttempt}     `
            .substring(0, 5)
            .split('')
            .map((letter, i)=> (
              <Letter key={i} letter={letter} />
            )
          )}
        </div>
      )}
      {(remainingAttempts > 0) && Array(remainingAttempts - 1).fill(null).map((_, i)=> (
        <div key={i} className={css.attempt}>
          <Letter />
          <Letter />
          <Letter />
          <Letter />
          <Letter />
        </div>
      ))}
    </div>
  )
}

const KeyboardLayout = ({ 
  keyboardStatus=[],
  onChange=noop
})=> {
  return (
    <div className={css.keyboard}>
      {KEYBOARD.map((row, i)=> (
        <div className={css.row} key={i}>
          {row.map((letter)=> (
            <button 
              key={letter}
              data-status={(keyboardStatus.find(s=> s.letter === letter) || { status: 'unknown' }).status}
              onClick={()=> onChange(letter)} 
              className={css.key} 
            >
              {letter === 'delete' ? <DeleteIcon /> : letter}
            </button>
          ))}
        </div>
      ))}
    </div>
  )
}

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      Guess a random word the web thought of, in 6 attempts or less!
    </p>
    <p className={css.rulesBlock}>
      The word is a <strong>5 letter word in Spanish</strong> from the dictionary
    </p>
    <p className={css.rulesBlock}>
      You may input any valid 5 letter workd from the dictionary. 
      The game will give you useful feedback after each attempt.
    </p>
    <p className={css.rulesBlock}>
      Letters in green are correctly placed.<br />
      Letters in yellow are part of the word, but are not in the correct position.<br />
      Letters in red are not part of the word at all
    </p>
    <p className={css.rulesBlock}>
      The keyboard will update to reflect the previous attempts.
    </p>
  </>
)

const Wordle = ()=> {

  const [streak, setStreak] = useState(0)
  const [word, setWord] = useState(generateRandomWord())
  const [currentAttempt, setCurrentAttempt] = useState('')
  const [attempts, setAttempts] = useState([])
  const [shake, setShake] = useState(false)
  const [showShare, setShowShare] = useState(false)

  const keyboardStatus = getKeyStatus({ attempts, solution: word })

  const isGameVictory = attempts.includes(word)
  const isGameDefeat = attempts.length === TOTAL_ATTEMPTS
  const isGameOver = isGameVictory || isGameDefeat

  const handleResetBoard = ()=> {
    setStreak(0)
    setWord(generateRandomWord())
    setAttempts([])
    setCurrentAttempt('')
  }

  const handleContinuePlaying = ()=> {
    setWord(generateRandomWord())
    setAttempts([])
    setCurrentAttempt('')
  }

  const handleClipboardClick = ()=> {
    let text = sharing[Math.min(20, streak)]
    if (streak > 20) text = text.replace("$", streak)
    const message = `${text}\nIntenta superar mi racha jugando en https://jorge.aguirre.sexy/games/wordle`
    navigator.clipboard.writeText(message)
  }

  const processLetter = useCallback(letter=> {
    if (isGameOver) return
    if (letter === 'delete') {
      setCurrentAttempt(currentAttempt.slice(0, -1))
    }
    else if (letter === 'enter') {
      if (currentAttempt.length < 5) return
      if (!words.includes(currentAttempt)) {
        setShake(true)
        return
      }
      setAttempts([...attempts, currentAttempt])
      setCurrentAttempt('')
    }
    else {
      setCurrentAttempt(c=> c.length === 5 ? c : `${c}${letter}`)
    }
  }, [attempts, currentAttempt, isGameOver])

  const processKeyDown = useCallback(event=> {
    if (event.key === 'Backspace') processLetter('delete')
    else if (event.key === 'Enter') processLetter('enter')
    else if (event.key.length === 1 && event.key.match(/[a-zñ]/)) processLetter(event.key)
  }, [processLetter])

  useEffect(()=> {
    document.addEventListener("keydown", processKeyDown)
    return ()=> document.removeEventListener("keydown", processKeyDown)
  }, [processKeyDown])

  useEffect(()=> {
    if (isGameVictory) setStreak(s=> s + 1)
  }, [isGameVictory])

  useEffect(()=> {
    if (shake) setTimeout(()=> setShake(false), 800)
  }, [shake])

  return (
    <>
      <GameHeader 
        title='Wordle' 
        rules={<GameRules />}
        options={[]}
        onReset={handleResetBoard}
      />
      <div className={css.main}>
        {streak > 0 && (
          <div className={css.streak}>
            <p className={css.streakTitle}>
              <span className={css.streakIntro}>Current score:</span>
              {' '}
              <span className={css.streakScore}>{streak}</span> {streak === 1 ? 'word' : 'words'}
            </p>
          </div>
        )}
        <Attempts
          currentAttempt={currentAttempt}
          attempts={attempts}
          solution={word}
          shake={shake}
        />
        {!isGameOver && (
          <KeyboardLayout 
            keyboardStatus={keyboardStatus}
            onChange={processLetter}
          />
        )}
        {isGameVictory && (
          <div className={css.gameOverPanel}>
            <p className={css.gameOverTitle}>Awesome work guessing the word!</p>
            <div className={css.gameOverButtons}>
              <button className={css.gameOverButton} onClick={handleContinuePlaying}>Keep going!</button>
              <button className={css.gameOverButton} onClick={()=> setShowShare(true)}>Share your score</button>
            </div>
          </div>
        )}
        {isGameDefeat && !isGameVictory && (
          <div className={css.gameOverPanel}>
            <p className={css.gameOverTitle}>
              Game over! The word you were looking for was <strong>{word}</strong>
            </p>
            <div className={css.gameOverButtons}>
              <button className={css.gameOverButton} onClick={()=> setShowShare(true)}>Share your score</button>
              <button className={css.gameOverButton} onClick={handleResetBoard}>⟳ Play again</button>
            </div>
          </div>
        )}
      </div>
      <Modal 
        visible={showShare}
        onClose={()=> setShowShare(false)}
        className={css.modal}
      >
        <h2 className={css.modalTitle}>Share your score</h2>
        <div className={css.modalContent}>
          <p className={css.modalText}>
            Thank you for playing my own implementation of <strong>Wordle</strong> and getting
            a score of <strong>{streak}</strong>!
          </p>
          <p className={css.modalText}>Share with your friends, challenge them and spread the love!</p>
          <button 
            onClick={handleClipboardClick}
            className={css.modalButton}
          >
            Copy score to clipboard
          </button>
        </div>
      </Modal>
    </>
  )

}

export default Wordle