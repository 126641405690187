import CodeArea               from '../../components/CodeArea/CodeArea'
import { CodeLine }           from '../../components/CodeArea/CodeArea'
import { FragmentUtils }      from '../../components/CodeArea/CodeArea'
import ConceptHeader          from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts         from '../../components/BackToConcepts/BackToConcepts'
import css                    from './TextCSS.module.css'

const CanIUseLink = ()=> (
  <a
    className={css.link}
    href='https://caniuse.com/#search=-webkit-background-clip'
    target='_blank'
    title='Can I Use'
    rel='nofollow noopener noreferrer'
  >
    Can I Use
  </a>
)

const TextCSS = ()=> (
  <>
    <ConceptHeader title='CSS based text effects to apply on a web' />
    <div className={css.main}>
      <p className={css.block}>
        Basic text formatting is very simple. It's just a few CSS properties
        that control the font-size, color, letter spacing...
      </p>
      <CodeArea>
        <CodeLine fragments={[{ content: 'p', color: 'orange', modifiers: 'strong' }, { content: ' {' }]} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('color', 'transparent')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('font-size', '<size>')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('font-weight', '<weight>')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('font-style', '<style>')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('letter-spacing', '<spacing>')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('text-decoration', '<decoration>')} />
        <CodeLine content='}' />
      </CodeArea>
      <p className={css.block}>
        Pretty neat, but I'm going to focus on a cooler text-effect that can be
        created using only modern CSS. It looks like this:
      </p>
      <div className={css.texture}>
        <p className={css.textureText}>CSS Power</p>
      </div>
      <p className={css.block}>
        I've added a CSS property that sets the text color to match
        the <code>background</code> instead of that of <code>color</code>. It
        may not feel like a big difference, but it is. Keep in mind that now
        we have a lot of CSS options that were not available before. For one,
        gradients!
      </p>
      <div className={css.gradient}>
        <p className={css.gradientText}>Always Ready</p>
      </div>
      <p className={css.block}>
        The CSS required for this is not standarized yet, and uses a prefix, but
        is implemented by all major browsers. Check <CanIUseLink /> for more
        details on browser compatibility. Below is what the CSS looks like:
      </p>
      <CodeArea>
        <CodeLine fragments={[{ content: 'p', color: 'orange', modifiers: 'strong' }, { content: ' {' } ]} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('color', 'transparent')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('background', '<custom-background>', 'Get creative here!')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('-webkit-background-clip', 'text')} />
        <CodeLine content='}' />
      </CodeArea>
      <p className={css.block}>
        The key to get this effect, is
        the <code>-webkit-background-clip</code> property. It's the one that
        sets the text color to match the value of <code>background</code>. If
        you are on desktop, hover over the text below to see something neat.
      </p>
      <div className={css.hoverable}>
        <p className={css.hoverableText}>I change on hover</p>
      </div>
      <p className={css.block}>
        And that's it, a simple trick for enhancing text decoration.
      </p>
    </div>
    <BackToConcepts />
  </>
)

export default TextCSS