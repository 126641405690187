import { useRef, useState, useEffect }      from 'react'
import ConceptHeader                        from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                       from '../../components/BackToConcepts/BackToConcepts'
import useInterval                          from '../../hooks/useInterval'
import css                                  from './OldPhone.module.css'

const LIMIT = 160

const LETTER_MAPPING = [
  {
    display: '1',
    letters: ['.', ',', '?', '!', "'",  '"', '1', '-', '(', ')', '@', '/', ':', '_']
  },
  {
    display: '2',
    letters: ['a', 'b', 'c', '2']
  },
  {
    display: '3',
    letters: ['d', 'e', 'f', '3']
  },
  {
    display: '4',
    letters: ['g', 'h', 'i', '4']
  },
  {
    display: '5',
    letters: ['j', 'k', 'l', '5']
  },
  {
    display: '6',
    letters: ['m', 'n', 'o', '6']
  },
  {
    display: '7',
    letters: ['p', 'q', 'r', 's', '7']
  },
  {
    display: '8',
    letters: ['t', 'u', 'v', '8']
  },
  {
    display: '9',
    letters: ['w', 'x', 'y', 'z', '9']
  },
  {
    display: '#',
    letters: ['+', '-', '#']
  },
  {
    display: '0',
    letters: [' ', '0']
  },
  {
    display: '*',
    letters: []
  }
]

const LONG_PRESS = 1000

const getLetter = ({ key, times, isUpperCase }) => {
  if (!key || key === '*') return ''
  const { letters } = LETTER_MAPPING.find(({ display }) => display === key)
  const index = times % letters.length
  const letter = letters[index]
  return isUpperCase ? letter.toUpperCase() : letter
}

const PhoneButton = ({ button, onPress, onRelease })=> (
  <button 
    data-button={button}
    className={css.button}
    onMouseDown={()=> onPress(button)} 
    onMouseUp={()=> onRelease(button)} 
  >
    {button}
  </button>
)

const OldPhone = ()=> {

  const lock = useRef()

  const [message, setMessage] = useState('')

  const [pressedKey, setPressedKey] = useState({ 
    key: '', 
    times: 0, 
    time: new Date().getTime() 
  })

  const [keyDown, setKeyDown] = useState({ 
    key: '', 
    time: new Date().getTime() 
  })

  const [isUpperCase, setUppercase] = useState(false)

  useEffect(()=> {
    lock.current = false
  }, [message])

  const remaining = LIMIT - message.length

  useInterval(()=> {
    const now = new Date().getTime()
    if (lock.current) return
    if (pressedKey && pressedKey.key && pressedKey.time && now - pressedKey.time > LONG_PRESS) {
      lock.current = true;
      setPressedKey({ key: '', times: 0, time: new Date().getTime() })
      const previousLetter = getLetter({ ...pressedKey, isUpperCase })
      addLetterToMessage(previousLetter)
    }
  }, 600)

  const addLetterToMessage = (letter)=> {
    if (!letter || remaining <= 0) return
    setMessage(current=> current + letter)
  }

  const handleKeyDown = (key)=> {
    setKeyDown({ key, time: new Date().getTime() })
  }

  const handleKeyUp = (key)=> {
    const { key: lastKey, time: lastTime } = keyDown
    const { key: previousKey, times: previousTimes } = pressedKey
    const time = new Date().getTime()
    const previousLetter = getLetter({ ...pressedKey, isUpperCase })
    // Pressing '*' toggles uppercase
    if (key === '*') {
      addLetterToMessage(previousLetter)
      setPressedKey({ key: '', times: 0 })
      setUppercase(current=> !current)
      return
    }
    // Long Press -> Add the previous letter to the message + The symbol pressed
    if (lastKey === key && time - lastTime > LONG_PRESS) {
      addLetterToMessage(`${previousLetter}${key}`)
      setPressedKey({ key: '', times: 0 })
      return
    }
    // Press a different key -> Add the previous letter to the message
    if (previousKey !== key) {
      addLetterToMessage(previousLetter)
      if (remaining === 0) return
      setPressedKey({ key, times: 0, time })
      return
    }
    // Same key pressed -> Increase the times pressed
    setPressedKey({ key, times: previousTimes + 1, time })
  }

  const handleClear = ()=> {
    setMessage('')
    setPressedKey({ key: '', times: 0, time: new Date().getTime() })
    setKeyDown({ key: '', time: new Date().getTime() })
  }

  const handleBack = ()=> {
    if (pressedKey.key) {
      setPressedKey({ key: '', times: 0, time: new Date().getTime() })
      setKeyDown({ key: '', time: new Date().getTime() })
    }
    else {
      setMessage(current=> current.slice(0, -1))
    }
  }

  return (
    <>
      <ConceptHeader title='Good old classic phone' />
      <div className={css.main}>
        <div className={css.message}>
          <code>
            {message}
            <strong>{getLetter({ ...pressedKey, isUpperCase })}</strong>
          </code>
          <label className={css.messageLabel}>
            {LIMIT - message.length - (pressedKey.key ? 1 : 0)} characters left
          </label>
        </div>
        <div className={css.keyboardExtras}>
          <button 
            className={css.extraButton}
            onClick={handleClear}
          >
            Clear
          </button>
          <button 
            className={css.extraButton}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
        <div className={css.keyboard}>
          {'123456789#0*'.split('').map((button)=> (
            <PhoneButton 
              key={button} 
              button={button} 
              onPress={handleKeyDown} 
              onRelease={handleKeyUp} 
            />
          ))}
        </div>
        <p className={css.block}>
          Before <strong>iPhones</strong> took over the world, there was the good old classic 
          phone. <strong>Nokia</strong> was a world famous brand that dominated the market, and,
          because mobile phones were just starting out, they were just mobile, not "smart".
        </p>
        <p className={css.block}>
          Their main purpose was calling (and receiving calls) - so their keypad was optimized for that,
          containing just the numbers and the most common symbols. Over time, writing text started to become
          a thing, and so, each number was mapped to different letters from the alphabet.
        </p>
        <p className={css.block}>
          By pressing it consecutively, you could cycle through the letters, and by pressing it for a long time,
          you could add the number itself to the message. This was what was used to send SMS in the early days.
        </p>
        <p className={css.block}>
          Because SMS stands for <strong>Short Message Service</strong>, the message was limited to <code>160 characters</code>.
        </p>
      </div>
      <BackToConcepts />
    </>
  )
}

export default OldPhone