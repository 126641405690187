import ReactDOM                       from 'react-dom'
import { BrowserRouter }              from 'react-router-dom'
import { Switch }                     from 'react-router-dom'
import { Route }                      from 'react-router-dom'

import Helmet                         from './meta/head'
import Menu                           from './components/Menu/Menu'
import ScrollToTop                    from './elements/ScrollToTop/ScrollToTop'

import Home                           from './views/Home/Home'
import Legal                          from './views/Legal/Legal'
import Recursion                      from './views/Recursion/Recursion'
import AboutMe                        from './views/AboutMe/AboutMe'
import Contact                        from './views/Contact/Contact'
import DeveloperResources             from './views/DeveloperResources/DeveloperResources'
import SelfieStudio                   from './views/SelfieStudio/SelfieStudio'
import PixelArtGenerator              from './views/PixelArtGenerator/PixelArtGenerator'
import TerminalConcepts               from './views/TerminalConcepts/TerminalConcepts'
import Profile                        from './views/Profile/Profile'
import WorldMap                       from './views/WorldMap/WorldMap'
import MintMyNFT                      from './views/MintMyNFT/MintMyNFT'

import NotFound                       from './views/NotFound/NotFound'

import Concepts                       from './views/Concepts/Concepts'
import Games                          from './views/Games/Games'

import MERNStack                      from './concepts/MERNStack/MERNStack'
import ReactHooks                     from './concepts/ReactHooks/ReactHooks'
import ReactPortals                   from './concepts/ReactPortals/ReactPortals'
import TextCSS                        from './concepts/TextCSS/TextCSS'
import SliderImageTransition          from './concepts/SliderImageTransition/SliderImageTransition'
import MongoExtractor                 from './concepts/MongoExtractor/MongoExtractor'
import ImageASCII                     from './concepts/ImageASCII/ImageASCII'
import VideoASCII                     from './concepts/VideoASCII/VideoASCII'
import CopyingClipboard               from './concepts/CopyingClipboard/CopyingClipboard'
import ArtASCII                       from './concepts/ArtASCII/ArtASCII'
import NeonLights                     from './concepts/NeonLights/NeonLights'
import SpeechSynthesiser              from './concepts/SpeechSynthesiser/SpeechSynthesiser'
import SpeechParser                   from './concepts/SpeechParser/SpeechParser'
import ScrollableFilter               from './concepts/ScrollableFilter/ScrollableFilter'
import MayTheForce                    from './concepts/MayTheForce/MayTheForce'
import FlipText                       from './concepts/FlipText/FlipText'
import DrawingCanvas                  from './concepts/DrawingCanvas/DrawingCanvas'
import ProductionWorks                from './concepts/ProductionWorks/ProductionWorks'
import FontsMatter                    from './concepts/FontsMatter/FontsMatter'
import BoxShadowGenerator             from './concepts/BoxShadowGenerator/BoxShadowGenerator'
import DestructuringJavascript        from './concepts/DestructuringJavascript/DestructuringJavascript'
import MongoTimestamps                from './concepts/MongoTimestamps/MongoTimestamps'
import TypewriterEffect               from './concepts/TypewriterEffect/TypewriterEffect'
import ShakersBrand                   from './concepts/ShakersBrand/ShakersBrand'
import TwitterHeader                  from './concepts/TwitterHeader/TwitterHeader'
import MouseTrackingSlowpoke          from './concepts/MouseTrackingSlowpoke/MouseTrackingSlowpoke'
import RageClicks                     from './concepts/RageClicks/RageClicks'
import FindTheMonkey                  from './concepts/FindTheMonkey/FindTheMonkey'
import CreatingNFT                    from './concepts/CreatingNFT/CreatingNFT'
import DynamicNFTs                    from './concepts/DynamicNFTs/DynamicNFTs'
import ColorGrid                      from './concepts/ColorGrid/ColorGrid'
import OldPhone                       from './concepts/OldPhone/OldPhone'

import TicTacToe                      from './games/TicTacToe/TicTacToe'
import Sudoku                         from './games/Sudoku/Sudoku'
import RockPaperScissorsLizardSpock   from './games/RockPaperScissorsLizardSpock/RockPaperScissorsLizardSpock'
import LightSwitch                    from './games/LightSwitch/LightSwitch'
import Memory                         from './games/Memory/Memory'
import Mastermind                     from './games/Mastermind/Mastermind'
import Wordle                         from './games/Wordle/Wordle'
import GuessTheFlag                   from './games/GuessTheFlag/GuessTheFlag'
import LinesOfCode                    from './games/LinesOfCode/LinesOfCode'
import TheEightQueens                 from './games/TheEightQueens/TheEightQueens'
import TheJumpingKnight               from './games/TheJumpingKnight/TheJumpingKnight'
import TileFlip                       from './games/TileFlip/TileFlip'


import './index.css'

const site = (
  <BrowserRouter>
    <Helmet />
    <ScrollToTop />
    <Menu />
    <Switch>
      <Route exact path='/' component={Home} />
      
      <Route path='/recursion' component={Recursion} />

      <Route exact path='/legal' component={Legal} />
      <Route exact path='/about-me' component={AboutMe} />
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/developer-resources' component={DeveloperResources} />
      <Route exact path='/selfie-studio' component={SelfieStudio} />
      <Route exact path='/world-map' component={WorldMap} />
      <Route exact path='/pixel-art-generator' component={PixelArtGenerator} />
      <Route exact path='/terminal-concepts' component={TerminalConcepts} />
      <Route exact path='/mint-my-nft' component={MintMyNFT} />

      <Route exact path='/concepts' component={Concepts} />
      <Route exact path='/games' component={Games} />

      <Route exact path='/concepts/reason-mern-stack' component={MERNStack} />
      <Route exact path='/concepts/react-hooks-intro' component={ReactHooks} />
      <Route exact path='/concepts/connecting-data-react-portals' component={ReactPortals} />
      <Route exact path='/concepts/text-effects-using-css-only' component={TextCSS} />
      <Route exact path='/concepts/using-slider-for-image-transition' component={SliderImageTransition} />
      <Route exact path='/concepts/mongodb-extractor-script' component={MongoExtractor} />
      <Route exact path='/concepts/image-to-ascii-characters' component={ImageASCII} />
      <Route exact path='/concepts/video-to-ascii-characters' component={VideoASCII} />
      <Route exact path='/concepts/copying-to-clipboard-js' component={CopyingClipboard} />
      <Route exact path='/concepts/ascii-art-jorge' component={ArtASCII} />
      <Route exact path='/concepts/neon-lights' component={NeonLights} />
      <Route exact path='/concepts/speech-synthesiser' component={SpeechSynthesiser} />
      <Route exact path='/concepts/speech-parser' component={SpeechParser} />
      <Route exact path='/concepts/scrollable-css-filter' component={ScrollableFilter} />
      <Route exact path='/concepts/may-the-force-be-with-you' component={MayTheForce} />
      <Route exact path='/concepts/flip-text' component={FlipText} />
      <Route exact path='/concepts/drawing-in-canvas' component={DrawingCanvas} />
      <Route exact path='/concepts/how-production-works' component={ProductionWorks} />
      <Route exact path='/concepts/fonts-matter' component={FontsMatter} />
      <Route exact path='/concepts/box-shadow-generator' component={BoxShadowGenerator} />
      <Route exact path='/concepts/destructuring-in-javascript' component={DestructuringJavascript} />
      <Route exact path='/concepts/mongo-timestamps' component={MongoTimestamps} />
      <Route exact path='/concepts/typewriter-effect' component={TypewriterEffect} />
      <Route exact path='/concepts/shakers-xyz-brand' component={ShakersBrand} />
      <Route exact path='/concepts/twitter-header' component={TwitterHeader} />
      <Route exact path='/concepts/mouse-tracking-slowpoke' component={MouseTrackingSlowpoke} />
      <Route exact path='/concepts/measuring-rage-clicks' component={RageClicks} />
      <Route exact path='/concepts/find-the-monkey' component={FindTheMonkey} />
      <Route exact path='/concepts/creating-an-nft' component={CreatingNFT} />
      <Route exact path='/concepts/dynamic-nfts' component={DynamicNFTs} />
      <Route exact path='/concepts/color-grid' component={ColorGrid} />
      <Route exact path='/concepts/old-phone' component={OldPhone} />

      <Route exact path='/games/tic-tac-toe' component={TicTacToe} />
      <Route exact path='/games/sudoku' component={Sudoku} />
      <Route exact path='/games/rock-paper-scissors-lizard-spock' component={RockPaperScissorsLizardSpock} />
      <Route exact path='/games/light-switch' component={LightSwitch} />
      <Route exact path='/games/memory' component={Memory} />
      <Route exact path='/games/mastermind' component={Mastermind} />
      <Route exact path='/games/wordle' component={Wordle} />
      <Route exact path='/games/guess-the-flag' component={GuessTheFlag} />
      <Route exact path='/games/lines-of-code' component={LinesOfCode} />
      <Route exact path='/games/the-eight-queens' component={TheEightQueens} />
      <Route exact path='/games/the-jumping-knight' component={TheJumpingKnight} />
      <Route exact path='/games/tile-flip' component={TileFlip} />

      <Route component={NotFound} />

    </Switch>
  </BrowserRouter>
)

ReactDOM.render(site, document.getElementById('root'))

