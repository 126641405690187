import { Link }           from 'react-router-dom'
import css                from './GameCard.module.css'

const GameCard = ({ to, cover, title,  description })=> (
  <div className={css.card}>
    <Link to={to} className={css.header}>
      <img className={css.image} alt={title} src={cover} />
    </Link>
    <Link to={to} className={css.title}>{title}</Link>
    <p className={css.description}>{description}</p>
  </div>
)

export default GameCard