import { useState, useEffect } from 'react'
import MenuIcon                from './MenuIcon'
import { Link }                from 'react-router-dom'
import css                     from './Menu.module.css'

const MenuLink = ({ to, icon, label, onClick })=> {
 return (
    <Link className={css.link} to={to} onClick={onClick}>
      <MenuIcon icon={icon} />
      <p className={css.text}>{label}</p>
    </Link>
 )
}

const Menu = ()=> {

  const [open, setOpen] = useState(false)

  useEffect(()=> {
    if (open) document.body.classList.add('menu--active')
    else document.body.classList.remove('menu--active')
  }, [open])

  return (
    <>
      <div 
        className={css.icon}
        data-status={open ? 'open' : 'closed'}
        onClick={()=> setOpen(!open)}
      >
        <span /><span /><span /><span />
      </div>
      <div className={css.content}>
        <div className={css.layout}>
          <MenuLink to='/' icon='home' label='Home' onClick={()=> setOpen(!open)} />
          <MenuLink to='/concepts' icon='blog' label='Concepts' onClick={()=> setOpen(!open)} />
          <MenuLink to='/about-me' icon='profile' label='About me' onClick={()=> setOpen(!open)} />
          <MenuLink to='/developer-resources' icon='resources' label='Resources' onClick={()=> setOpen(!open)} />
          <MenuLink to='/contact' icon='contact' label='Contact' onClick={()=> setOpen(!open)} />
          <MenuLink to='/legal' icon='legal' label='Legal' onClick={()=> setOpen(!open)} />
        </div>
      </div>
    </>
  )

}

export default Menu