import { useState, useEffect }                      from 'react'
import GameHeader                                   from '../../components/GameHeader/GameHeader'
import { isCellEmpty, determineTurn, isGameOver }   from './resources/utils'
import { determineBestMove, gameWinner }            from './resources/utils'
import css                                          from './TicTacToe.module.css'

const EMPTY_BOARD = [['','',''], ['','',''], ['','','']]

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      Tic Tac Toe is all about aligning 3 pieces in a row, column or in either of the diagonals
      before the opponent does.
    </p>
    <p className={css.rulesBlock}>
      Click on any square of the board to place your piece there, and then wait for the
      opponent to do the same.
    </p>
    <p className={css.rulesBlock}>
      If the board is full and nobody has won the game, it's considered a tie!
    </p>
  </>
)


const CellContent = ({ play='' })=> {
  if (play === 'X') return (
    <svg className={css.cellContent} data-type='x' viewBox='0 0 100 100'>
      <line x1="15" y1="15" x2="85" y2="85"  />
      <line x1="85" y1="15" x2="15" y2="85"  />
    </svg>
  )
  if (play === 'O') return (
    <svg className={css.cellContent} data-type='o' viewBox='0 0 100 100'>
      <circle cx="50" cy="50" r="30" />
    </svg>
  )
  else return null
}

const Board = ({boardState, onClick})=> (
  <div className={css.board}>
    {boardState.map((row, row_index)=> (
      row.map((cell, column_index)=> (
        <div 
          key={`${row_index}-${column_index}`} 
          className={css.cell}
          onClick={()=> onClick(row_index, column_index)}
        >
          <CellContent play={cell} />
        </div>
      ))
    ))}
  </div>
)

const BoardHint = ({ boardState })=> {
  if (isGameOver(boardState)) {
    const winner = gameWinner(boardState)
    if (winner === 'T') return <p data-result='tie' className={css.hint}>It's a tie</p>
    if (winner === 'X') return <p data-result='p1' className={css.hint}>Player 1 wins!</p>
    return <p data-result='p2' className={css.hint}>Player 2 wins!</p>
  }
  const turn = determineTurn(boardState) === 'X' ? '1' : '2'
  return (
    <p 
      data-result={`p${turn}`} 
      className={css.hint}
    >
      Player {turn}'s turn
    </p>
  )
}


const TicTacToe = ()=> {

  const [boardState, setBoardState] = useState(EMPTY_BOARD)
  const [mode, setMode] = useState('1-player')

  useEffect(()=> {
    if (mode === '2-player') return
    if (isGameOver(boardState)) return
    if (determineTurn(boardState) !== 'O') return
    let isCurrent = true
    const [row, column] = determineBestMove(boardState)
    setTimeout(()=> isCurrent && updateBoardGame(row, column, 'O'), 250)
    return ()=> isCurrent = false
  }, [boardState, mode])

  const updateBoardGame = (row, column, value)=> {
    setBoardState(currentBoardState=> {
      const new_board_state = [['','',''], ['','',''], ['','','']]
      for (let i=0; i<3; i++) {
        for (let j=0; j<3; j++) {
          new_board_state[i][j] = (row === i && column === j) ? value : currentBoardState[i][j]
        }
      }
      return new_board_state
    })
  }

  const handleResetGame = ()=> setBoardState(EMPTY_BOARD)

  const handleChangeMode = ()=> {
    setMode(currentMode=> currentMode === '2-player' ? '1-player' : '2-player')
    setBoardState(EMPTY_BOARD)
  }

  const handleClick = (row, column)=> {
    const isEmpty = isCellEmpty(row, column, boardState)
    const isTheGameOver = isGameOver(boardState)
    if (!isEmpty ||isTheGameOver) return
    const player = determineTurn(boardState)
    if (mode === '1-player' && player !== 'X') return
    updateBoardGame(row, column, player)
  }

  return (
    <>
      <GameHeader 
        title='Tic Tac Toe' 
        rules={<GameRules />}
        onReset={handleResetGame}
        options={[
          {
            label: `${mode === '1-player' ? '2 ' : '1'} player mode`,
            description: 'Switch between 1-player (vs AI) and 2-player modes (you have to provide the friend!)',
            onClick: handleChangeMode
          }
        ]}
      />
      <div className={css.main}>
        <div className={css.controls}>
          <BoardHint boardState={boardState} />
        </div>
        <Board 
          boardState={boardState}
          onClick={handleClick}
        />
      </div>
    </>
  )
}

export default TicTacToe