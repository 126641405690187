import { useState }           from 'react'
import ConceptHeader          from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts         from '../../components/BackToConcepts/BackToConcepts'
import CodeArea               from '../../components/CodeArea/CodeArea'
import { CodeLine }           from '../../components/CodeArea/CodeArea'
import { FragmentUtils }      from '../../components/CodeArea/CodeArea'
import css                    from './ReactHooks.module.css'

const ReactHooks = ()=> {

  const [happyFace, setHappyFace] = useState(true)

  return (
    <>
      <ConceptHeader title='Getting started with React Hooks' />
      <div className={css.main}>
        <p className={css.block}>
          It all starts with React v14.
        </p>
        <p className={css.block}>
          I know what you are thinking, React Hooks are actually a part
          of v16.6, and that's absolutely correct. But this isn't about
          the origin of hooks, but rather, on how to get started with them.
        </p>
        <p className={css.block}>
          React v14 included <strong>stateless functional components</strong>,
          which were a more perfomant approach for components that relied only
          on their props (and had no <code>state</code>).
        </p>
        <p className={css.block}>
          Some components could then be written as simple, stateless function.
          Whose output was either static, or depended solely on the component's
          props. Something like this (a component with an emoji and a button):
        </p>
        <CodeArea>
          <CodeLine fragments={FragmentUtils.JS.import('React', 'react')} />
          <CodeLine />
          <CodeLine fragments={[{content: 'export default ()=> ', color: 'pink'}, {content: '('}]} />
          <CodeLine indent={1} fragments={FragmentUtils.HTML.tag('div', [{key: 'className', value: 'concept'}])} />
          <CodeLine indent={2} fragments={FragmentUtils.HTML.tag('div', [{key: 'className', value: 'concept-emoji'}])} />
          <CodeLine indent={3} content='🙂' />
          <CodeLine indent={2} fragments={FragmentUtils.HTML.tagClose('div')} />
          <CodeLine indent={2} fragments={FragmentUtils.HTML.tag('button', [{key: 'className', value: 'concept-button'}])} />
          <CodeLine indent={3} content='Click me' />
          <CodeLine indent={2} fragments={FragmentUtils.HTML.tagClose('button')} />
          <CodeLine indent={1} fragments={FragmentUtils.HTML.tagClose('div')} />
          <CodeLine content=')' />
        </CodeArea>
        <p className={css.block}>
          Although they were, more performant, they had some limitations and the community did not fully embrace them.
          Stateful components can skip re-renders by implementing the lifecycle method <code>shouldComponentUpdate</code>,
          which is unavailable for their stateless counterparts. Stateless functions always re-render when their props change,
          even if it's not necessary.
        </p>
        <p className={css.block}>
          This was the main flaw of stateless components. On the one hand, they did not handle state, meaning they relied heavily
          on props, and the number of props could get large very soon. On the other hand, there was no way to optimize these 
          components, and every prop change triggered a re-render.
        </p>
        <p className={css.block}>
          And that's where hooks come to the rescue. They allow this stateless components to have some sort of state, and
          listen to specific prop changes, and mutate accordingly. It sort of allows you to add the stateful component features
          you want to a stateless component.
        </p>
        <p className={css.block}>
          Let's suppose you had to make the stateless component above change its emoji when the button is clicked, like this:
        </p>
        <div className={css.collapsible}>
          <div className={css.face}>
            {happyFace ? '🙂' : '🙃'}
          </div>
          <button
            className={css.button}
            onClick={()=> setHappyFace(!happyFace)}
          >
            Click me!
          </button>
        </div>
        <p className={css.block}>
          You could probably handle this by adding a listener prop, and then the parent keeping track of the state 
          and updating the emoji within this component. But adding props to a stateless component can hurt it's performance,
          since there is no way to optimize them. Also, keep in mind, that you'd have to refactor the parent component
          as well, which is not always easy to do.
        </p>
        <p className={css.block}>
          You could rewrite the stateless component as a stateful component and add state. This rewrite should take 
          less than 5 minutes, since it's a very simple component. This just throws away any possible performance gains,
          and makes the component a lot more complicated for a very subtle change.
        </p>
        <p className={css.block}>
          But, since we now have hooks, you can add a hook here, and it's the perfect scenario to get
          started with hooks. The state-hooked component looks like this:
        </p>
        <CodeArea>
          <CodeLine fragments={FragmentUtils.JS.import('React', 'react')} />
          <CodeLine fragments={FragmentUtils.JS.import('{ useState }', 'react')} />
          <CodeLine />
          <CodeLine fragments={[{content: 'export default ()=> ', color: 'pink'}, {content: '{', color: 'red'}]} />
          <CodeLine />
          <CodeLine
            indent={1}
            fragments={[
              {content: 'const ', color: 'violet-2'},
              {content: '[happyFace, setHappyFace] = useState('},
              {content: 'true', color: 'red'},
              {content: ')'}
            ]}
          />
          <CodeLine />
          <CodeLine indent={1} fragments={[{content: 'return ', color: 'violet-2'}, {content: '('}]} />
          <CodeLine indent={2} fragments={FragmentUtils.HTML.tag('div', [{key: 'className', value: 'concept'}])} />
          <CodeLine indent={3} fragments={FragmentUtils.HTML.tag('div', [{key: 'className', value: 'concept-emoji'}])} />
          <CodeLine
            indent={4}
            fragments={[
              {content: '{ ', color: 'red'},
              {content: 'happyFace '},
              {content: '? ', color: 'blue'},
              {content: '"🙂" '},
              {content: ': ', color: 'blue'},
              {content: '"🙃" '},
              {content: '}', color: 'red'}
            ]}
          />
          <CodeLine indent={3} fragments={FragmentUtils.HTML.tagClose('div')} />
          <CodeLine indent={3} fragments={[{content: '<button', color: 'violet-3'}]} />
          <CodeLine
            indent={4}
            fragments={[
              {content: 'className', color: 'green'},
              {content: '="'},
              {content: 'concept-button', color: 'wood'},
              {content: '"'}
            ]}
          />
          <CodeLine
            indent={4}
            fragments={[
              {content: 'onClick', color: 'green'},
              {content:'='},
              {content: '{', color: 'red'},
              {content: '()=> ', color: 'pink'},
              {content: 'setHappyFace('},
              {content: '!', color: 'red'},
              {content: 'happyFace)'},
              {content: '}', color: 'red'}
            ]}
          />
          <CodeLine indent={3} fragments={[{content: '>', color: 'violet-3'}]} />
          <CodeLine indent={4} content='Click me' />
          <CodeLine indent={3} fragments={FragmentUtils.HTML.tagClose('button')} />
          <CodeLine indent={2} fragments={FragmentUtils.HTML.tagClose('div')} />
          <CodeLine indent={1} content=')' />
          <CodeLine fragments={[{content: '}', color: 'red'}]} />
        </CodeArea>
        <p className={css.block}>
          We have not included any new props for the component, and we have not had to refactor it entirely. ¡Yay!
        </p>
        <p className={css.block}>
          This was the simplest scenario, but hooks allow syncing functional components to props, trigger specific 
          functions only when the component is first mounted... It has <strong>a lot</strong> of potential and use cases.
        </p>
      </div>
      <BackToConcepts />
    </>
  )

}

export default ReactHooks