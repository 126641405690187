import { useState, useEffect, useRef }          from 'react'
import ConceptHeader                            from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                           from '../../components/BackToConcepts/BackToConcepts'
import CodeArea, { CodeLine }                   from '../../components/CodeArea/CodeArea'
import gecko                                    from './resources/gecko.jpeg'
import css                                      from './ScrollableFilter.module.css'


const GithubLink = ()=> (
  <a 
    href='https://github.com/facebook/react/issues/6436'
    target='_blank'
    rel='noopener noreferrer'
  >
    here
  </a>
)

const ScrollableFilter = ()=> {

  const scroll = useRef(null)

  const [filter, setFilter] = useState('')
 
  useEffect(()=> {
    const element = scroll.current
    element.addEventListener('wheel', handleScroll)
    return ()=> element.removeEventListener('wheel', handleScroll)
  })

  const handleScroll = event=> {
    event.preventDefault()
    const direction_y = event.deltaY !== 0 ? event.deltaY > 0 ? 'forward' : 'backwards' : 'none'
    requestAnimationFrame(()=> {
      const current_grayscale = Math.min(Math.max(1, filter), 99)
      if (direction_y === 'forward') setFilter(current_grayscale+2)
      else if (direction_y === 'backwards') setFilter(current_grayscale-2)
    })
  }
  
  return (
    <>
      <ConceptHeader title='Scrollable CSS Filter' />
      <div className={css.main}>
        <img 
          className={css.image}
          ref={scroll}
          src={gecko}
          alt="Scrollable Gecko"
          style={{filter: `grayscale(${filter}%)`}} 
        />
        <p className={css.block}>
          This was suprisingly complicated because of how React handles passive events. I had to bring to this
          three different hooks: <code>useState</code>, <code>useEffect</code> and <code>useRef</code>. Let's see 
          how it plays out
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' scroll', color: 'blue' }, 
            { content: ' = '},
            { content: 'useRef(', color: 'wood'},
            { content: 'null', color: 'pink'},
            { content: ')', color: 'wood'},
          ]} />
          <CodeLine />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' [', color: 'orange' }, 
            { content: 'filter', color: 'blue' }, 
            { content: ', '}, 
            { content: 'setFilter', color: 'blue' }, 
            { content: ']', color: 'orange' }, 
            { content: ' = '},
            { content: 'useState(', color: 'wood'},
            { content: '""', color: 'green'},
            { content: ')', color: 'wood'},
          ]} />
          <CodeLine />
          <CodeLine fragments={[
            { content: 'useEffect(', color: 'wood' }, 
            { content: '()=> {', color: 'yellow' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' element', color: 'blue-2' }, 
            { content: ' = '},
            { content: 'scroll', color: 'blue'},
            { content: '.'},
            { content: 'current', color: 'orange-3'},
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'element', color: 'blue-2' }, 
            { content: '.' }, 
            { content: 'addEventListener(', color: 'orange-3' }, 
            { content: '"wheel"', color: 'green' }, 
            { content: ', ' }, 
            { content: '<handler>', color: 'violet', modifiers: 'italic' }, 
            { content: ')', color: 'orange-3' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'return ', color: 'orange' }, 
            { content: '()=> ', color: 'violet-2' }, 
            { content: 'element', color: 'blue-2' }, 
            { content: '.' }, 
            { content: 'removeEventListener(', color: 'orange-3' }, 
            { content: '"wheel"', color: 'green' }, 
            { content: ', ' }, 
            { content: '<handler>', color: 'violet', modifiers: 'italic' }, 
            { content: ')', color: 'orange-3' }, 
          ]} />
          <CodeLine fragments={[
            { content: '}', color: 'yellow' },
            { content: ')', color: 'wood' },
          ]} />
        </CodeArea>
        <p className={css.block}>
          We have to use both <code>useRef</code> and <code>useEffect</code> for this to work because of the 
          passive listener behavoiur thingy (check out the details <GithubLink />). That way the wheel event 
          will be handled properly
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: '<img', color: 'red' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'src', color: 'orange-2' },
            { content: '=' },
            { content: '{', color: 'blue-2' },
            { content: '<image>', color: 'violet', modifiers: [ 'italic' ] },
            { content: '}', color: 'blue-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'ref', color: 'orange-2' },
            { content: '=' },
            { content: '{', color: 'blue-2' },
            { content: 'scroll', color: 'blue' },
            { content: '}', color: 'blue-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'style', color: 'orange-2' },
            { content: '=' },
            { content: '{', color: 'blue-2' },
            { content: '{ ', color: 'orange-3' },
            { content: 'filter', color: 'yellow' },
            { content: ': ' },
            { content: '`grayscale(', color: 'green-2' },
            { content: '${', color: 'pink' },
            { content: 'filter', color: 'blue' },
            { content: '}', color: 'pink' },
            { content: '%)`', color: 'green-2' },
            { content: ' }', color: 'orange-3' },
            { content: '}', color: 'blue-2' },
          ]} />
          <CodeLine fragments={[
            { content: '/>', color: 'red' },
          ]} />
        </CodeArea>
        <p className={css.block}>
          So we've created an image and we've linked it to the ref element created with the <code>useRef</code> hook,
          also, we are using the <code>filter</code> value from the state to set a grayscale filter (which is what we've aimed for).
          Now, all that's left is dealing with the event listener handler. I've separated it because that way it's much easier to change
          to do other cool things when scrolling through an element.
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'handleScroll', color: 'blue' },
            { content: ' = ' },
            { content: 'event', color: 'wood' },
            { content: '=> {', color: 'yellow' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'event', color: 'wood' },
            { content: '.' },
            { content: 'preventDefault()', color: 'pink' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'direction_y', color: 'blue-2' },
            { content: ' = ' },
            { content: 'event', color: 'wood' },
            { content: '.' },
            { content: 'deltaY', color: 'pink' },
            { content: ' !== ', color: 'orange' },
            { content: '0', color: 'yellow' },
            { content: ' ? ', color: 'orange' },
            { content: 'event', color: 'wood' },
            { content: '.' },
            { content: 'deltaY', color: 'pink' },
            { content: ' > ', color: 'orange' },
            { content: '0', color: 'yellow' },
            { content: ' ? ', color: 'orange' },
            { content: '"forward"', color: 'green' },
            { content: ' : ', color: 'orange' },
            { content: '"backwards"', color: 'green' },
            { content: ' : ', color: 'orange' },
            { content: '"none"', color: 'green' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'requestAnimationFrame(', color: 'wood' },
            { content: '()=> {', color: 'violet-3' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'current_grayscale', color: 'violet-2' },
            { content: ' = ' },
            { content: 'Math', color: 'red' },
            { content: '.' },
            { content: 'min(', color: 'pink' },
            { content: 'Math', color: 'red' },
            { content: '.' },
            { content: 'max(', color: 'pink' },
            { content: '1', color: 'yellow' },
            { content: ', ' },
            { content: 'filter', color: 'blue' },
            { content: ')', color: 'pink' },
            { content: ', ', color: 'red' },
            { content: '99', color: 'yellow' },
            { content: ')', color: 'pink' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'if (', color: 'orange' },
            { content: 'direction_y', color: 'blue-2' },
            { content: ' === ', color: 'orange' },
            { content: '"forward"', color: 'green' },
            { content: ') ', color: 'red' },
            { content: 'setFilter(', color: 'blue' },
            { content: 'current_grayscale', color: 'violet-2' },
            { content: ' + ', color: 'orange' },
            { content: '2', color: 'yellow' },
            { content: ')', color: 'blue' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'if (', color: 'orange' },
            { content: 'direction_y', color: 'blue-2' },
            { content: ' === ', color: 'orange' },
            { content: '"backwards"', color: 'green' },
            { content: ') ', color: 'red' },
            { content: 'setFilter(', color: 'blue' },
            { content: 'current_grayscale', color: 'violet-2' },
            { content: ' - ', color: 'orange' },
            { content: '2', color: 'yellow' },
            { content: ')', color: 'blue' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '}', color: 'violet-3' },
            { content: ')', color: 'red' },
          ]} />
          <CodeLine fragments={[
            { content: '}', color: 'yellow' },
          ]} />
        </CodeArea>
        <p className={css.block}>
          Thanks to the scroll's <code>deltaY</code> it's easy to tell what the scroll is doing, and increase
          or decrease the filter accordingly. A bit of clamping to avoid going overboard, and that's pretty much
          it.
        </p>
      </div>
      <BackToConcepts />
    </>
  )
}

export default ScrollableFilter