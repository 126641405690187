import { useState }         from 'react'
import ConceptHeader        from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts       from '../../components/BackToConcepts/BackToConcepts'
import css                  from './FlipText.module.css'

const flip_char = c=> {
  
  const source = [ 
    'a', 'b', 'c', 'd', 'e', 
    'f', 'g', 'h', 'i', 'j', 
    'k', 'l', 'm', 'n', 'o', 
    'p', 'q', 'r', 's', 't', 
    'u', 'v', 'w', 'x', 'y', 
    'z', '[', ']', '(', ')', 
    '{', '}', '?', '\u00BF', '!', 
    "'", ',', '.', '_', ';', 
    '9', '6', '¡', '¿', 'A',
    'C', 'D', 'E', 'T'
  ]

  const destination = [ 
    '\u0250', 'q', '\u0254', 'p', '\u01DD', 
    '\u025F', 'b', '\u0265', '\u0131', '\u0638', 
    '\u029E', '\u05DF', '\u026F', 'u', 'o', 
    'd', 'b', '\u0279', 's', '\u0287', 
    'n', '\u028C', '\u028D', 'x', '\u028E', 
    'z', ']', '[', ')', '(', 
    '}', '{', '\u00BF', '?', '\u00A1', 
    ',', "'", '\u02D9', '\u203E', '\u061B', 
    '6', '9', '!', '?', 'ᗄ',
    'Ɔ', 'ᗡ', 'Ǝ', '⊥'
  ]

  const index = source.indexOf(c)
  return index === -1 ? c : destination[index]
}

const flip_text = text=> text.split('').reverse().map(flip_char).join('')

const FlipText = ()=> {

  const [source, setSource] = useState('')

  return (
    <>
      <ConceptHeader title='Flip a text (upside down)' />
      <div className={css.main}>
        <p className={css.block}>
          Flipping text upside down was surpisingly easy and widely supported accross diffrent programs. You could use some kind of CSS
          transformation like <code>rotate(180deg)</code> to get a visually perfect upside down text. It would be visually perfect,
          but not exportable.
        </p>
        <p className={css.block}>
          Instead I looked through <strong>Unicode</strong>'s less known characters and found upside-down versions of most text 
          characters, which is what I'm using here. That way, if you copy the output text and paste it somewhere else (like a social 
          network, or an email) it will be pasted upside down. No CSS, no JS, no tricks, just unusual characters from 
          the <strong>Unicode</strong>.
        </p>
        <input 
          className={css.input}
          type='text' 
          value={source} 
          onChange={e=> setSource(e.target.value)} 
          placeholder='Type something here'
        />
        <p className={css.result}>
          {source ? flip_text(source) : '...'}
        </p>
        <p className={css.block}>
          Well, obviously I coudn't find a suitable character for every letter in the alphabet in both uppercase and lowercase, but I got
          all the lowercase letters, a few uppercases, and a few numbers. You should be able to write a full sentence without bumping into
          missing characters.
        </p>
      </div>
      <BackToConcepts />
    </>
  )
}

export default FlipText