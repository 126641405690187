import ConceptHeader       from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts      from '../../components/BackToConcepts/BackToConcepts'
import css                 from './NeonLights.module.css'

const NeonLights = ()=> (
  <>
    <ConceptHeader title='Neon Lights Effect' />
    <div className={css.main}>
      <p className={css.text} contentEditable suppressContentEditableWarning>Let's party</p>
    </div>
    <p className={css.block}>
      This is an animation with the <code>box-shadow</code> property and a font I obtained 
      from <strong>Google Fonts</strong>. All of it put together is probably less than 10
      lines of code. The most difficult part was choosing the right <code>font-size</code>.
    </p>
    <p className={css.block}>
      I've used the <code>contentEditable</code> attribute 
      to make the text editable by the user, give it a shot (I've scoped it to the neon-like text).
      The text has very few glyphs (basic letters, numbers and punctuation signs), so don't try to 
      make fancy text with it because it will be a disappointment.
    </p>
    <BackToConcepts />
  </>
)

export default NeonLights