import ConceptHeader                  from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                 from '../../components/BackToConcepts/BackToConcepts'
import CodeArea, { CodeLine }         from '../../components/CodeArea/CodeArea'
import css                         from './CopyingClipboard.module.css'

const CopyingClipboard = ()=> {

  const link = (
    <a 
      href='https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API' 
      target='_blank' 
      rel='noopener noreferrer'
    >
      MDN site
    </a>
  )

  const handleInputButtonClick = ()=> {
    const copyText = document.getElementById('clipboard-input')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
  }

  const handleSpanButtonClick = ()=> {
    const range = document.createRange()
    range.selectNode(document.getElementById('clipboard-codearea'))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
  }

  const handleInvisibleButtonClick = ()=> {
    const faux = document.createElement('textarea')  
    faux.value = 'Volverán las oscuras golondrinas\nEn tu balcón sus nidos a colgar'                                 
    faux.setAttribute('readonly', '')                
    faux.style.position = 'absolute'                 
    faux.style.left = '-9999px'                      
    document.body.appendChild(faux)                  
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false                                    
    faux.select()                                    
    document.execCommand('copy')                   
    document.body.removeChild(faux)                  
    if (selected) {                                 
      document.getSelection().removeAllRanges()    
      document.getSelection().addRange(selected)   
    }
  }

  const Button = ({ onClick })=> (
    <button onClick={onClick} className={css.button}>
      <svg className={css.buttonIcon} viewBox="0 0 488.3 488.3">
        <g>
          <path d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124    C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124    c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"/>
          <path d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227    c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6    V38.6C439.65,17.3,422.35,0,401.05,0z"/>
        </g>
      </svg>
    </button>
  )

  return (
    <>
      <ConceptHeader title='Copying to the clipboard with JS' />
      <div className={css.main}>
        <p className={css.block}>
          This is a surprisingly simple way to get data to the OS clipboard. It <strong>does</strong> involve
          some JS, but not much, not very hard, not browser-inconsistent (IE8 is <strong>not</strong> a browser). 
        </p>
        <p className={css.block}>
          For this to work, we need to be reacting to a user event (such as a click). The copying method will not
          work otherwise. The copying JS code we have to execute to copy text, is really simple, it's just: 
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'execCommand(', color: 'violet-2' },
            { content: '"copy"', color: 'green' },
            { content: ')', color: 'violet-2' },
          ]} />
        </CodeArea>
        <p className={css.block}>
          This will get whatever text we have selected and deliver place it on the clipboard. The tricky 
          part is getting the text we want to be recognized as "the selected text" (and thus, copied). 
          Let's see how it's done
        </p>
        <h2 className={css.header}>I: Copying from input (Easy)</h2>
        <div className={css.section}>
          <input className={css.input} type='text' id='clipboard-input' placeholder='Test it out' />
          <Button onClick={handleInputButtonClick} />
        </div>
        <p className={css.block}>
          This is the simplest.
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'const', color: 'orange' },
            { content: ' copy', color: 'blue' },
            { content: ' = ' },
            { content: ' element', color: 'wood' },
            { content: '=> {', color: 'pink' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const', color: 'orange' },
            { content: ' copyText', color: 'blue-2' },
            { content: ' = ' },
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'getElementById(', color: 'violet-2' },
            { content: 'element', color: 'wood' },
            { content: ')', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'copyText', color: 'blue-2' },
            { content: '.' },
            { content: 'select()', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'copyText', color: 'blue-2' },
            { content: '.' },
            { content: 'setSelectionRange(', color: 'violet-2' },
            { content: '0', color: 'red' },
            { content: ', ' },
            { content: '99999', color: 'red' },
            { content: ')', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'execCommand(', color: 'violet-2' },
            { content: '"copy"', color: 'green' },
            { content: ')', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'window', color: 'yellow' },
            { content: '.' },
            { content: 'getSelection()', color: 'violet-2' },
            { content: '.' },
            { content: 'removeAllRanges()', color: 'violet-3' },
          ]} />
          <CodeLine fragments={[
            { content: '}', color:'pink' },
          ]} />
        </CodeArea>
        <p className={css.block}>
          With a coulpe of lines of code we can get the content of the input to be selected, and then copied. 
          Not much sweating here, but, having an input with the text we want is a heavy constraint. Fortunately,
          it's not our only solution. 
        </p>
        <h2 className={css.header}>II: Copying from text elements (Medium)</h2>
        <div className={css.section}>
          <CodeArea id='clipboard-codearea'>
            <CodeLine fragments={[
              { content: 'const', color: 'orange' },
              { content: ' copy', color: 'blue' },
              { content: ' = ' },
              { content: ' element', color: 'wood' },
              { content: '=> {', color: 'pink' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'const', color: 'orange' },
              { content: ' range', color: 'blue-2' },
              { content: ' = ' },
              { content: ' document', color: 'yellow' },
              { content: '.' },
              { content: 'createRange()', color: 'violet-2' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'range', color: 'blue-2' },
              { content: '.' },
              { content: 'selectNode(', color: 'violet-2' },
              { content: 'document', color: 'yellow' },
              { content: '.' },
              { content: 'getElementById(', color: 'violet-3' },
              { content: 'element', color: 'wood' },
              { content: ')', color: 'violet-3' },
              { content: ')', color: 'violet-2' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'window', color: 'yellow' },
              { content: '.' },
              { content: 'getSelection()', color: 'violet-2' },
              { content: '.' },
              { content: 'removeAllRanges()', color: 'violet-3' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'window', color: 'yellow' },
              { content: '.' },
              { content: 'getSelection()', color: 'violet-2' },
              { content: '.' },
              { content: 'addRange(', color: 'violet-3' },
              { content: 'range', color: 'blue-2' },
              { content: ')', color: 'violet-3' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'document', color: 'yellow' },
              { content: '.' },
              { content: 'execCommand(', color: 'violet-2' },
              { content: '"copy"', color: 'green' },
              { content: ')', color: 'violet-2' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'window', color: 'yellow' },
              { content: '.' },
              { content: 'getSelection()', color: 'violet-2' },
              { content: '.' },
              { content: 'removeAllRanges()', color: 'violet-3' },
            ]} />
            <CodeLine fragments={[
              { content: '}', color:'pink' },
            ]} />
          </CodeArea>
          <Button onClick={handleSpanButtonClick} />
        </div>
        <p className={css.block}>
          Okey, this got trickier, since now we have <code>document.createRange()</code> involved. This is the actual approach for the DOM
          elements that don't have a <code>.select()</code> method that leverages this for us.
        </p>
        <p className={css.block}>
          A little tricker but not too much, we've only added 2 additional lines of code.
        </p>
        <h2 className={css.header}>III: Copying out of nowhere (Hard)</h2>
        <div className={css.section}>
          <Button onClick={handleInvisibleButtonClick} />
        </div>
        <p className={css.block}>
          This is the hardest, since we have to do quite a few things here, including a "dirty" DOM element append.
        </p>
        <p className={css.block}>
          So, basically, now the text is nowhere in the DOM, so we have to place it there, in a 
          "visible" <code>textarea</code>. This I do not like, but see no way around, so we just put 
          it very far away from the rest of the content, and hope nobody notices.
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'const', color: 'orange' },
            { content: ' copy', color: 'blue' },
            { content: ' = ' },
            { content: ' text', color: 'wood' },
            { content: '=> {', color: 'pink' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const', color: 'orange' },
            { content: ' faux', color: 'blue-2' },
            { content: ' = ' },
            { content: ' document', color: 'yellow' },
            { content: '.' },
            { content: 'createElement(', color: 'violet-2' },
            { content: '"textarea"', color: 'green' },
            { content: ')' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'faux', color: 'blue-2' },
            { content: '.' },
            { content: 'value', color: 'violet-2' },
            { content: ' = ', color: 'green' },
            { content: 'text', color: 'wood' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'faux', color: 'blue-2' },
            { content: '.' },
            { content: 'setAttribute(', color: 'violet-2' },
            { content: '"readonly"', color: 'green' },
            { content: ', ' },
            { content: '""', color: 'green' },
            { content: ')', color: 'violet-2'  },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'faux', color: 'blue-2' },
            { content: '.' },
            { content: 'style', color: 'blue' },
            { content: '.' },
            { content: 'position', color: 'violet' },
            { content: ' = ' },
            { content: '"absolute"', color: 'green' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'faux', color: 'blue-2' },
            { content: '.' },
            { content: 'style', color: 'blue' },
            { content: '.' },
            { content: 'left', color: 'violet' },
            { content: ' = ' },
            { content: '"-9999px"', color: 'green' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'body', color: 'blue' },
            { content: '.' },
            { content: 'appendChild(', color: 'violet-2' },
            { content: 'faux', color: 'blue-2' },
            { content: ')', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'selected', color: 'blue-2' },
            { content: ' = ' },
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'getSelection()', color: 'violet-2' },
            { content: '.' },
            { content: 'rangeCount', color: 'violet' },
            { content: ' > ', color: 'orange' },
            { content: '0', color: 'blue' },
            { content: ' ? ', color: 'orange' },
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'getSelection()', color: 'violet-2' },
            { content: '.' },
            { content: 'getRangeAt(', color: 'violet-3' },
            { content: '0', color: 'blue' },
            { content: ')', color: 'violet-3' },
            { content: ' : ', color: 'orange' },
            { content: 'false', color: 'blue' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'window', color: 'yellow' },
            { content: '.' },
            { content: 'getSelection()', color: 'violet-2' },
            { content: '.' },
            { content: 'removeAllRanges()', color: 'violet-3' },
          ]} />
          
          <CodeLine indent={1} fragments={[
            { content: 'faux', color: 'blue-2' },
            { content: '.' },
            { content: 'select()', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'execCommand(', color: 'violet-2' },
            { content: '"copy"', color: 'green' },
            { content: ')', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'document', color: 'yellow' },
            { content: '.' },
            { content: 'body', color: 'orange' },
            { content: '.' },
            { content: 'removeChild(', color: 'violet-2' },
            { content: 'faux', color: 'blue-2' },
            { content: ')', color: 'violet-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'if (', color: 'orange' },
            { content: 'selected', color: 'blue-2' },
            { content: ') ', color: 'orange' },
            { content: '{', color: 'red' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'window', color: 'yellow' },
            { content: '.' },
            { content: 'getSelection()', color: 'violet-2' },
            { content: '.' },
            { content: 'removeAllRanges()', color: 'violet-3' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'window', color: 'yellow' },
            { content: '.' },
            { content: 'getSelection()', color: 'violet-2' },
            { content: '.' },
            { content: 'addRange(', color: 'violet-3' },
            { content: 'selected', color: 'blue-2' },
            { content: ')', color: 'violet-3' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '}', color:'red' },
          ]} />
          <CodeLine fragments={[
            { content: '}', color:'pink' },
          ]} />
        </CodeArea>
        <p className={css.block}>
          Since we've added a textarea, the rest of the code could simply be what I've described in 
          the first step, whoever, we like complicated but neat things, so it's not going to be that simple.
        </p>
        <p className={css.block}>
          Since the text we are going to copy is not in the DOM, we are going to preserve whatever 
          selection the user had on the DOM, by copying the current selection range (if any) and then 
          later restoring it.
        </p>
        <h2 className={css.header}>IIII: The future</h2>
        <p className={css.block}>
          This felt hacky to me while writing it, and apparently, it also felt kind of improvable to a bunch of
          other people, so there is a Work In Progress API to handle clipboard elements.
        </p>
        <p className={css.block}>
          Like most new APIs this requires user permissions, a secure context (HTTPS), and works asynchronously with
          promises. It's very promising, but unfortunately, not finished yet. Check out the details in the {link}
        </p>
          
      </div>
      <BackToConcepts />
    </>
  )
}

export default CopyingClipboard