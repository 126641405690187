import { useState }    from 'react'
import useInterval     from '../../hooks/useInterval'
import css             from './Cover.module.css'

const LETTERS = [
  'a', 'b', 'c', 
  'cm', 'cn', 'co', 
  'com', 'con', 'coo', 
  'cooj', 'cook', 'cool', 
  'cool', 'cool', 'cool',
  'coo', 'co', 'c', ''
]

const Cover = ()=> {

  const [letterIndex, setLetterIndex] = useState(0)

  useInterval(()=> {
    setLetterIndex((letterIndex + 1) % LETTERS.length)
  }, 250)

  return (
    <div className={css.main}>
      <code className={css.letter}>
        {LETTERS[letterIndex]}
      </code>
    </div>
  )
}

export default Cover