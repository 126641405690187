import { useState, useEffect }    from 'react'
import ConceptHeader              from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts             from '../../components/BackToConcepts/BackToConcepts'
import css                        from './RageClicks.module.css'

const getRangeInfo = ({ clicks })=> {
  if (!clicks.length) return { rage: false }
  const lastClick = clicks[clicks.length - 1]
  if (Date.now() - lastClick.time > 5000) return { rage: false }
  // Consecutive clicks in the same area (100x100 pixel box)
  const coordinates = clicks.slice(-2).reduce((acc, { pageX, pageY })=> ({
    x: [...acc.x, pageX],
    y: [...acc.y, pageY]
  }), {x: [], y: []})
  const box = {
    minX: Math.min(...coordinates.x),
    maxX: Math.max(...coordinates.x),
    minY: Math.min(...coordinates.y),
    maxY: Math.max(...coordinates.y)
  }
  const inRange = (box.maxX - box.minX) < 100 && (box.maxY - box.minY) < 100
  if (!inRange) return { rage: false }
  return { rage: true, box }
}

const RageBox = ({ rageInfo })=> {
  if (!rageInfo || !rageInfo.rage || !rageInfo.box) return null
  const left = `${(rageInfo.box.maxX + rageInfo.box.minX) / 2 - 50}px`
  const top = `${(rageInfo.box.maxY + rageInfo.box.minY) / 2 - 50}px`
  return (
    <div className={css.box} style={{ top, left }} />
  )
}

const RageClicks = ()=> {

  const [clicks, setClicks] = useState([])
  const [rageInfo, setRageInfo] = useState({ rage: false })

  useEffect(()=> {
    const listener = ({ detail, pageX, pageY, target })=> {
      setClicks(clicks=> ([
        ...clicks.slice(-9),
        { pageX, pageY, detail, target, time: Date.now() }
      ]))
    }
    document.addEventListener('click', listener)
    return ()=> document.removeEventListener("click", listener)
  }, [])

  useEffect(()=> {
    const rageInfo = getRangeInfo({ clicks })
    setRageInfo(rageInfo)
    if (rageInfo.rage) {
      const timeout = setTimeout(()=> {
        setRageInfo({ rage: false })
      }, 4000)
      return ()=> clearTimeout(timeout)
    }
  }, [clicks])

  return (
    <>
      <ConceptHeader title='Detecting rage clicks' />
      <div className={css.main}>
        <p className={css.block}>
          Rage clicking is when a user tries to perform an action, and, upon not seen any change in the UI,
          clicks the same area multiple times. This is a great indicator that the user is expecting something to happen
          or at least some feedback that his/her action is being processed.
        </p>
        <div className={css.buttonArea}>
          <button className={css.moneyButton}>🤑 Click for free money</button>
        </div>
        <p className={css.block}>
          This is a metric you probably want to keep track of, as rage clicking is undesirable for almost any web application.
          Maybe if you are building a click-based game this would be fine, but for a web application, probably not. 
        </p>
        <div className={css.buttonArea}>
          <button className={css.blockchainButton}>🧙 Get 1 BTC</button>
        </div>
        <p className={css.block}>
          Even if it's not an error, like a user adding multiple identical products to a shopping cart 
          (which could trigger a <strong>rage click</strong>), it indicates the user is putting a lot of energy 
          into the action.
        </p>
        <div className={css.free}>
          <button className={css.unicornButton}>🦄 Adopt a unicorn</button>
        </div>
        <p className={css.block}>
          So go ahead, pick your poison. Do you want free money? Would you like 1 BTC? Perhaps adoptin a unicorn? 
          Whatever you want. Click on your favorite button and see what happens.
          3 quick clicks trigger the rage effect!
        </p>
      </div>
      <RageBox rageInfo={rageInfo} />
      <BackToConcepts />
    </>
  )
}

export default RageClicks