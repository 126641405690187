import { useState, useEffect }   from 'react'
import ConceptHeader             from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts            from '../../components/BackToConcepts/BackToConcepts'
import Switch                    from '../../components/Switch/Switch'
import css                       from './ProductionWorks.module.css'

const ProductionWorks = ()=> {

  const [fast, setFast] = useState(false)
  const [good, setGood] = useState(false)
  const [cheap, setCheap] = useState(false)
  const [lastActive, setLastActive] = useState('')

  useEffect(()=> {
    if (!fast || !good || !cheap) return
    const remaining_features = ['fast', 'good', 'cheap'].filter(f=> f !== lastActive)
    const random = Math.floor(Math.random()*2)
    const feature = remaining_features[random]
    if (feature === 'fast') setFast(!fast)
    if (feature === 'good') setGood(!good)
    if (feature === 'cheap') setCheap(!cheap)
  }, [fast, good, cheap, lastActive])

  const handleClick = feature=> {
    setLastActive(feature)
    if (feature === 'fast') setFast(!fast)
    if (feature === 'good') setGood(!good)
    if (feature === 'cheap') setCheap(!cheap)
  }

  return (
    <>
      <ConceptHeader title='How production works' />
      <div className={css.main}>
        <div className={css.switch}>
          <Switch className={css.fast} checked={fast} onClick={()=> handleClick('fast')} />
          <span className={css.label}>Fast</span>
        </div>
        <div className={css.switch}>
          <Switch className={css.good} checked={good} onClick={()=> handleClick('good')} />
          <span className={css.label}>Good</span>
        </div>
        <div className={css.switch}>
          <Switch className={css.cheap} checked={cheap} onClick={()=> handleClick('cheap')} />
          <span className={css.label}>Cheap</span>
        </div>
      </div>
      <BackToConcepts />
    </>
  )
}

export default ProductionWorks