import { Helmet }               from 'react-helmet'
import { withRouter }           from 'react-router'
import data                     from './head.json'
import concepts_ld              from './common/concepts.json'
import ld                       from './json-ld.json'

export default withRouter(({ location })=> {
  const { title, description } = data.find(element=> element.url === location.pathname) || data[0]
  const json_ld = getJSONLD(location.pathname)
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={`https://jorge.aguirre.sexy${location.pathname}`} />
      
      <meta name='twitter:creator' content="@ja_codes" />
      <meta name='twitter:card' content='summary' />
      {json_ld && json_ld.image && <meta name='twitter:image' content={json_ld.image} />}
      
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={`https://jorge.aguirre.sexy${location.pathname}`} />
      {json_ld && json_ld.image && <meta name='og:image' content={json_ld.image} />}
      
      {json_ld && <script type="application/ld+json">{JSON.stringify(json_ld)}</script>}
    </Helmet>
  )
})


const getJSONLD = url=> {
  const data = ld.find(element => element.url === url)
  if (!data) return null
  const page_ld = data.ld
  const result = {
    ...concepts_ld,
    ...page_ld
  }
  return result
}