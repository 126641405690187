import { useEffect, useState }                                             from 'react'
import GameHeader                                                          from '../../components/GameHeader/GameHeader'
import { generateRandomSudokuBoard, generateSudokuPuzzle, areBoardsEqual } from './resources/utils'
import css                                                                 from './Sudoku.module.css'

const LoadingSpinner = ()=> <div className={css.spinner} />

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      Sudoku is a puzzle based on a small number of very simple rules:
    </p>
    <ul className={css.rulesList}>
      <li>Every square has to contain a single number</li>
      <li>Only the numbers from 1 through to 9 can be used</li>
      <li>Each 3×3 box can only contain each number from 1 to 9 once</li>
      <li>Each vertical column can only contain each number from 1 to 9 once</li>
      <li>Each horizontal row can only contain each number from 1 to 9 once</li>
    </ul>
    <p className={css.rulesBlock}>
      You may add a number by selecting that cell and writing it!
    </p>
    <p className={css.rulesBlock}>
      Use the hint button if you are stuck, and the "check" button to see how well
      you are doing!
    </p>
  </>
)

const SudokuBoard = ({ board, activeCell, onClick, onKeyPress, onKeyDown })=> {

  const getCellHighlightValue = cell=> {
    if (!cell.value) return 'none'
    if (cell.isHint) return 'hinted'
    if (cell.isCorrect) return 'correct'
    if (cell.isCorrect === false) return 'incorrect'
    return 'none'
  }

  const isCellSelected = (row, column)=> {
    return activeCell && activeCell.row === row && activeCell.column === column
  }

  return (
    <div 
      className={css.board}
      tabIndex='0' 
      onKeyPress={onKeyPress} 
      onKeyDown={onKeyDown}
    >
      {board.map((row, row_index)=> (
        row.map((cell, column_index)=> (
          <div 
            key={`${row_index}-${column_index}`}
            className={css.cell}
            data-type={cell.isBase ? 'base' : 'user'}
            data-column={column_index+1}
            data-row={row_index+1}
            data-status={cell.value ? 'data' : 'empty'}
            data-highlight={getCellHighlightValue(cell)}
            data-selection={isCellSelected(row_index, column_index) ? 'selected' : 'unselected'}
            onClick={()=> !cell.isBase && onClick(row_index, column_index)}
          >
            {cell.value}
          </div>
        ))
      ))}
    </div>
  )
}

const Sudoku = ()=> {

  const [loading, setLoading] = useState(true)
  const [boardSolution, setBoardSolution] = useState(null)
  const [baseBoard, setBaseBoard] = useState([])
  const [activeCell, setActiveCell] = useState(null)
  const [gameStatus, setGameStatus] = useState('solving')

  useEffect(()=> {
    if (!loading) return
    const board = generateRandomSudokuBoard()
    const empty_board = generateSudokuPuzzle(board)
    const base_board = empty_board.map(row=> row.map(value=> ({ value, isBase: !!value })))
    setBaseBoard(base_board)
    setBoardSolution(board)
    setLoading(false)
  }, [loading])

  useEffect(()=> {
    if (!baseBoard || !baseBoard.length || !boardSolution) return
    const values = baseBoard.map(row=> row.map(cell=> cell.value))
    if (areBoardsEqual(boardSolution, values)) return setGameStatus('solved-ok')
    const empty_cells = baseBoard
      .map((row_data, row)=> row_data.map(({ value }, column)=> ({ row, column, value })))
      .reduce((acc, current)=> [...acc, ...current], [])
      .filter(({ value })=> !value)
    if (!empty_cells.length) return setGameStatus('solved-with-errors')
    else return setGameStatus('solving')
  }, [baseBoard, boardSolution])

  const handleResetBoard = ()=> setLoading(true)

  const handleCellClick = (row, column)=> {
    if (!activeCell) setActiveCell({ row, column })
    else if (activeCell.row === row && activeCell.column === column) setActiveCell(null)
    else setActiveCell({ row, column })
  }

  const handleKeyClick = e=> {
    if (!activeCell) return
    const number = e.key
    const validNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (!validNumbers.includes(number)) return
    setBaseBoard(current_board=> current_board.map((row, row_index)=> {
      if (row_index !== activeCell.row) return [...row]
      return row.map((cell, column_index)=> {
        if (column_index !== activeCell.column) return cell
        return { value: +number, base: false }
      })
    }))
    setActiveCell(null)
  }

  const handleKeyDown = e=> {
    if (activeCell && e.key === 'Backspace') {
      setBaseBoard(current_board=> current_board.map((row, row_index)=> {
        if (row_index !== activeCell.row) return [...row]
        return row.map((cell, column_index)=> {
          if (column_index !== activeCell.column) return cell
          return { ...cell, value: null }
        })
      }))
      setActiveCell(null)
    }
  }

  const handleCheckBoard = ()=> {
    setBaseBoard(current_board=> current_board.map((row, row_index)=> (
      row.map((cell, column_index)=> {
        if (!cell.value) return cell
        return { ...cell, isCorrect: boardSolution[row_index][column_index] === cell.value }
      })
    )))
  }

  const handleBoardHint = ()=> {
    const values = baseBoard
      .map((row_data, row)=> row_data.map(({ value }, column)=> ({ row, column, value })))
      .reduce((acc, current)=> [...acc, ...current], [])
      .filter(({ value })=> !value)
    if (!values.length) return
    const random_tip = Math.floor(Math.random() * values.length)
    const selected_value = values[random_tip]
    const selected_solution = boardSolution[selected_value.row][selected_value.column]
    setBaseBoard(current_board=> current_board.map((row, row_index)=> {
      if (row_index !== selected_value.row) return [...row]
      return row.map((cell, column_index)=> {
        if (column_index !== selected_value.column) return cell
        return { ...cell, value: selected_solution, isBase: true, isHint: true }
      })
    }))
  }

  return (
    <>
      <GameHeader 
        title='Sudoku' 
        rules={<GameRules />}
        options={[
          {
            label: 'Check',
            onClick: handleCheckBoard,
            disabled:  loading || gameStatus === 'solved-ok',
            description: 'Checks the current Sudoku board for errors'
          },
          {
            label: 'Hint',
            onClick: handleBoardHint,
            disabled: loading || gameStatus === 'solved-ok',
            description: 'Gives you a hint for the current Sudoku board'
          }
        ]}
        onReset={handleResetBoard}
      />
      {gameStatus === 'solved-ok' && (
        <div className={css.congratulations}>
          <p>
            <strong>Congratulations!</strong> You've successfully solved this board!
          </p>
        </div>
      )}
      <div className={css.main}>
        {loading ? <LoadingSpinner /> : (
          <SudokuBoard 
            board={baseBoard} 
            activeCell={activeCell}
            onClick={handleCellClick}
            onKeyPress={handleKeyClick}
            onKeyDown={handleKeyDown}
          />
        )}
      </div>
    </>
  )

}

export default Sudoku