import { useCallback, useEffect, useState }  from 'react'
import css                                   from'./TypedContent.module.css'

const TypedContent = ({ sentences=[] })=> {

  const [text, setText] = useState('')
  const [currentSentence, setCurrentSentence] = useState(0)
  const [isDeleting, setDeleting] = useState(false)

  const tick = useCallback(()=> {
    const sentence = sentences[currentSentence]
    const new_text = sentence.substring(0, text.length + (isDeleting ? -1 : +1))
    setText(new_text)
  }, [currentSentence, isDeleting, sentences, text])

  useEffect(()=> setDeleting(false), [currentSentence])

  useEffect(()=> {
    const sentence = sentences[currentSentence]
    // When the sentence is complete - wait & set to deleting
    if (!isDeleting && sentence === text) {
      const timeout = setTimeout(()=> setDeleting(true), 1000)
      return ()=> clearTimeout(timeout)
    }
    // When the sentence is fully deleted - wait & move to the next sentence
    if (isDeleting && !text) {
      const timeout = setTimeout(()=> setCurrentSentence(current=> (current+1)%sentences.length), 500)
      return ()=> clearTimeout(timeout)
    }
    // Add or remove a letter
    const delta = 80
    const timeout = setTimeout(tick, isDeleting ? delta/2 : delta)
    return ()=> clearTimeout(timeout) 
  }, [sentences, text, isDeleting, currentSentence, tick])


  return (
    <span 
      className={css.content}
      data-status={`${sentences[currentSentence] === text ? 'complete' : 'incomplete'}`}
    >
      {text}
    </span>
  )
}

export default TypedContent