import { useEffect }      from 'react'
import ReactDOM           from 'react-dom'
import css                from './Modal.module.css'
import './Modal.css'

const noop = ()=> {}

const Modal = ({ className='', visible, onClose=noop, children })=> {
  
  useEffect(()=> {
    if (visible) document.body.classList.add('modal--active')
    else document.body.classList.remove('modal--active')
  }, [visible])

  const handleContentClick = event=> {
    event.preventDefault()
    event.stopPropagation()
  }

  if (!visible) return null

  return ReactDOM.createPortal((
    <div className={css.backdrop} onClick={onClose}>
      <div className={`${css.content} ${className}`} onClick={handleContentClick}>
        {children}
      </div>
    </div>
  ), document.getElementById('modal'))
}

export default Modal