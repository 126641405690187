import css from './Cover.module.css'

const Cover = ()=> (
  <div className={css.main}>
    <div className={css.box}>
      <code className={css.code}>box-shadow</code>
    </div>
  </div>
)

export default Cover