import BackToConcepts                         from '../../components/BackToConcepts/BackToConcepts'
import CodeArea, { CodeLine, FragmentUtils }  from '../../components/CodeArea/CodeArea'
import ConceptHeader                          from '../../components/ConceptHeader/ConceptHeader'
import TypedContent                           from '../../elements/TypedContent/TypedContent'
import css                                    from './TypewriterEffect.module.css'

const TypedJsLink = ()=> (
  <a 
    href='https://github.com/mattboldt/typed.js/'
    rel='noopener noreferrer'
    target='_blank'
  >
    typed.js
  </a>
)

const CssTricksLink = ()=> (
  <a 
    href='https://css-tricks.com/snippets/css/typewriter-effect/'
    rel='noopener noreferrer'
    target='_blank'
  >
    css-tricks
  </a>
)

const TypewriterEffect = ()=> (
  <>
    <ConceptHeader title='The typewriter effect' />
    <div className={css.main}>
      <p className={css.block}>
        I first saw the typewriting effect on a friend's personal website, and I found it very 
        pleasing, so, when I started building my website, I just <strong>knew</strong> I had to use it
        somewhere.
      </p>
      <p className={css.block}>
        My first approach was using a third party library, and I quickly found <TypedJsLink />, which was
        super easy to install and setup, and gave me no troubles whatsoever. However, I liked the idea of 
        doing the effect by myself.
      </p>
      <p className={css.block}>
        A few months later I found this <CssTricksLink /> post, explaining how to do the typewriting with JS, 
        and I knew this was the perfect chance to build a <strong>React component</strong> and stop relying
        on the external library.
      </p>
      <p className={css.block}>
        It turned out to be much simpler than I had anticipated, and, although this component has way less features
        than the library, it suited my needs and allowed me to remove one dependency from my project. Plus, the
        component is very style agnostic, so it's pretty simple to reuse
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.Colors({
          contents: ['import ', 'React', ', { ', 'useEffect', ', ', 'useState', ' } ', 'from ', '"react"'],
          colors: ['violet-2', 'blue', '', 'blue-2', '', 'blue-2', '', 'violet-2',  'green']
        })} />
        <CodeLine />
        <CodeLine fragments={FragmentUtils.Colors({
          contents: ['const ', 'TypedContent ', '= ', '(', '{ ', 'sentences', '=', '[]', ' }', ')', '=> {'],
          colors: ['violet-2', 'orange-3', '', 'pink', '', 'wood', '', 'red', '', 'pink', 'pink']
        })} />
        <CodeLine indent={1} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['const ', '[', 'text', ', ', 'setText', ']', ' = ', 'useState(', '""', ')'],
          colors: ['violet-2', '', 'orange', '', 'orange', '', '', 'blue-2', 'green', 'blue-2']
        })} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['const ', '[', 'currentSentence', ', ', 'setCurrentSentence', ']', ' = ', 'useState(', '0', ')'],
          colors: ['violet-2', '', 'orange', '', 'orange', '', '', 'blue-2', 'red', 'blue-2']
        })} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['const ', '[', 'isDeleting', ', ', 'setDeleting', ']', ' = ', 'useState(', 'false', ')'],
          colors: ['violet-2', '', 'orange', '', 'orange', '', '', 'blue-2', 'violet-2', 'blue-2']
        })} />
        <CodeLine indent={1} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['const ', 'tick ', '= ', '()=> {'],
          colors: ['violet-2', 'orange', '', 'pink']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['const ', 'sentence', ' = ', 'sentences', '[', 'currentSentence', ']'],
          colors: ['violet-2', 'yellow', '', 'wood', '', 'orange', '']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['const ', 'new_text', ' = ', 'sentence'],
          colors: ['violet-2', 'yellow', '', 'yellow']
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['.', 'substring(', '0', ', ', 'text', '.', 'length', ' + ', '(', 'isDeleting', ' ? ', '-1', ' : ', '+1', ')', ')'],
          colors: ['', 'green-2', 'red', '', 'red', '', 'green-2', '', 'violet-3', 'orange', '', 'red', '', 'red', 'violet-3', 'green-2']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['setText(', 'new_text', ')'],
          colors: ['orange', 'yellow', 'orange']
        })} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['}'],
          colors: ['pink']
        })} />
        <CodeLine indent={1} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['useEffect(', '()=> ', 'setDeleting(', 'false', ')', ', ', '[', 'currentSentence', ']', ')'],
          colors: ['blue-2', 'pink', 'orange', 'violet-2', 'orange', '', 'blue-2', 'orange', 'blue-2', 'blue-2']
        })} />
        <CodeLine indent={1} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['useEffect(', '()=> {'],
          colors: ['blue-2', 'pink']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['const ', 'sentence', ' = ', 'sentences', '[', 'currentSentence', ']'],
          colors: ['violet-2', 'yellow', '', 'wood', '', 'orange', '']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.JS.comment("Sentence complete - wait & set to deleting")} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['if  ', '(', '!', 'isDeleting', ' && ', 'sentence', ' === ', 'text', ') {'],
          colors: ['violet-2', 'pink', 'red', 'orange', '', 'yellow', '', 'orange', 'pink']
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['const ', 'timeout', ' = ', 'setTimeout(', '()=> ', 'setDeleting(', 'true', ')', ', ', '1000', ')'],
          colors: ['violet-2', 'yellow', '', 'blue', 'pink', 'orange', 'violet-2', 'orange', '', 'red', 'blue']
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['return ', '()=> ', 'clearTimeout(', 'timeout', ')'],
          colors: ['violet-2', 'yellow', 'blue', 'yellow', 'blue']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['}'],
          colors: ['pink']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.JS.comment("Sentence deleted - wait & advance sentence")} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['if  ', '(', 'isDeleting', ' && ', '!', 'text', ') {'],
          colors: ['violet-2', 'pink', 'orange', '', '', 'orange', 'pink']
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['const ', 'timeout', ' = ', 'setTimeout(', '()=> {', ],
          colors: ['violet-2', 'yellow', '', 'blue', 'pink' ]
        })} />
        <CodeLine indent={4} fragments={FragmentUtils.Colors({
          contents: ['const ', 'total', ' = ', 'sentences', '.', 'length'],
          colors: ['violet-2', 'yellow', '', 'wood', '', 'green-2' ]
        })} />
        <CodeLine indent={4} fragments={FragmentUtils.Colors({
          contents: ['setCurrentSentence(', 'current', '=> ', '(', 'current', '+', '1', ')', '%', 'total', ')'],
          colors: ['orange', 'wood', 'orange-3', 'yellow', 'wood','', 'red', 'yellow', '', 'yellow', 'red' ]
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['}', ', ', '500', ')'],
          colors: ['pink', '', 'red', 'blue' ]
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['return ', '()=> ', 'clearTimeout(', 'timeout', ')'],
          colors: ['violet-2', 'yellow', 'blue', 'yellow', 'blue']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['}'],
          colors: ['pink']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.JS.comment("Add or remove a letter (δ = typing speed)")} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['const ', 'delta', ' = ', '80'],
          colors: ['violet-2', 'yellow', '', 'red' ]
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['const ', 'timeout', ' = ', 'setTimeout(', 'tick', ', ', 'isDeleting', ' ? ', 'delta', '/', '2', ' : ', 'delta', ')'],
          colors: ['violet-2', 'yellow', '', 'blue', 'orange', '', 'orange', '', 'yellow', '', 'red', '', 'yellow', 'blue']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['return ', '()=> ', 'clearTimeout(', 'timeout', ')'],
          colors: ['violet-2', 'yellow', 'blue', 'yellow', 'blue']
        })} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['}', ', ', '[', 'text', ', ', 'isDeleting', ', ', 'currentSentence', '])'],
          colors: ['pink', '', 'blue-2', 'orange', '', 'orange', '', 'orange', 'blue-2']
        })} />
        <CodeLine indent={1} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: ['return ', '('],
          colors: ['violet-2', 'pink']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['<span'],
          colors: ['violet-3']
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['className', '=', '"TypedContent"'],
          colors: ['yellow', '', 'green']
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['data-status', '=', '{', '`', '${', 'sentences[', 'currentSentence', ']', ' === ', 'text', ' ? ', '"done"', ' : ', '"active"', '}', '`', '}'],
          colors: ['yellow', '', 'violet', 'yellow', 'pink', 'wood', 'orange', 'wood', '', 'orange', '', 'green', '', 'green',  'pink', 'yellow', 'violet']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['>'],
          colors: ['violet-3']
        })} />
        <CodeLine indent={3} fragments={FragmentUtils.Colors({
          contents: ['{', 'text', '}'],
          colors: ['yellow', 'orange', 'yellow']
        })} />
        <CodeLine indent={2} fragments={FragmentUtils.Colors({
          contents: ['</span>'],
          colors: ['violet-3']
        })} />
        <CodeLine indent={1} fragments={FragmentUtils.Colors({
          contents: [')'],
          colors: ['pink']
        })} />
        <CodeLine fragments={FragmentUtils.Colors({
          contents: ['}'],
          colors: ['pink']
        })} />
      </CodeArea>
      <p className={css.block}>
        That's it! That's the whole component! It works out of the box & it does not require any special CSS, 
        it will inherit size, color & other style properties from its container. Modifying the delta value, and the
        timeouts will change the typing speed.
      </p>
      <p className={css.block}>
        If you don't plan on adding anything else, you can probably delete the <strong>className</strong> and
        the <strong>data-status</strong> attributes. I put them there, because it allows me to create 
        the blinking caret effect with this tiny bit of extra CSS.
      </p>
      <CodeArea>
        <CodeLine fragments={[
          { content: '.TypedContent', color: 'yellow' }, 
          { content: ' {', color: 'yellow' } 
        ]} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('border-right', '2px solid rgba(255, 255, 255, .8)')} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('padding-right', '3px')} />
        <CodeLine fragments={[ { content: '}', color: 'yellow' } ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: '.TypedContent[', color: 'yellow' }, 
          { content: 'data-status=', color: 'blue-2' }, 
          { content: '"complete"', color: "green" } ,
          { content: '] {', color: 'yellow' }, 
        ]} />
        <CodeLine indent={1} fragments={FragmentUtils.CSS.property('animation', 'typedBorderEdge .5s infinite alternate linear')} />
        <CodeLine fragments={[ { content: '}', color: 'yellow' } ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: '@keyframes ', color: 'pink', modifiers: 'strong' }, 
          { content: 'typedBorderEdge', color: 'red' }, 
          { content: ' {', color: 'yellow' } 
        ]} />
        <CodeLine indent={1} fragments={[ { content: 'from {', color: 'violet-2' } ]} />
        <CodeLine indent={2} fragments={FragmentUtils.CSS.property('border-right', '2px solid rgba(255, 255, 255, .8)')} />
        <CodeLine indent={1} fragments={[ { content: '}', color: 'violet-2' } ]} />
        <CodeLine indent={1} fragments={[ { content: 'to {', color: 'violet-2' } ]} />
        <CodeLine indent={2} fragments={FragmentUtils.CSS.property('border-right', '2px solid rgba(255, 255, 255, .05)')} />
        <CodeLine indent={1} fragments={[ { content: '}', color: 'violet-2' } ]} />
        <CodeLine fragments={[ { content: '}', color: 'yellow' } ]} />
      </CodeArea>
      <p className={css.block}>
        And that's that, I sincerely hope{' '}
        <TypedContent sentences={[
          'you enjoyed learning this.',
          'you found it useful.',
          'you let me know if you liked it.',
          'find happiness, wherever you are.',
        ]} />
      </p>
    </div>
    <BackToConcepts />
  </>
)

export default TypewriterEffect