

import { useEffect, useState }    from 'react'
import GameHeader                 from '../../components/GameHeader/GameHeader'
import { generateBoard, toggle }  from './resources/utils'
import Modal                      from '../../components/Modal/Modal'
import CopyToClipboardButton      from '../../components/CopyToClipboard/CopyToClipboard'
import css                        from './TileFlip.module.css'

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      Tile flip is literally about flipping tiles. Clicking on a tile will flip it, along with
      the adjacent tiles. The goal is to flip all the tiles to white.
    </p>
    <p className={css.rulesBlock}>
      Click on any tile to flip it, and watch how the adjacent tiles flip as well.
    </p>
  </>
)

const TileFlip = ()=> {

  const [board, setBoard] = useState(generateBoard('normal'))
  const [difficulty, setDifficulty] = useState('normal')
  const [moves, setMoves] = useState(0)
  const [isComplete, setComplete] = useState(false)
  const [modalClosed, setModalClosed] = useState(false)

  useEffect(() => {
    const allWhite = board.flat().every((tile) => !tile)
    if (allWhite) setComplete(true)
  }, [board])

  const handleDifficultyClick = ()=> {
    const newDifficulty = difficulty === 'normal' ? 'hard' : 'normal'
    setDifficulty(newDifficulty)
    setBoard(generateBoard(newDifficulty))
  }

  const handleResetGame = ()=> {
    setBoard(generateBoard(difficulty))
    setMoves(0)
    setComplete(false)
    setModalClosed(false)
  }

  const handleTileClick = (row, col) => {
    if (isComplete) return
    const newBoard = board.map((row) => [...row])
    toggle(newBoard, row, col)
    setBoard(newBoard)
    setMoves(moves + 1)
  }

  const getClipboardText = ()=> {
    const score = `Look at that, I completed the ${difficulty} tile flip challenge in ${moves} moves!`;
    const claim = 'Give it a try at https://jorge.aguirre.sexy/games/tile-flip'
    return [score, claim].join('\n')
  }

  return (
    <>
      <GameHeader 
        title='Tile Flip' 
        rules={<GameRules />}
        onReset={handleResetGame}
        options={[
          { 
            label: 'Change difficulty', 
            description: 'Switch between the normal board (5x5) and the difficult board (7x7)',
            onClick: handleDifficultyClick 
          },
        ]}
      />
      <div className={css.main}>
        <div className={css.board} data-difficulty={difficulty}>
          {board.map((row, rowIndex) =>
            row.map((tile, colIndex) => (
              <div
                key={`${rowIndex}-${colIndex}`}
                className={css.tile}
                data-tile={tile ? 'black' : 'white'}
                onClick={() => handleTileClick(rowIndex, colIndex)}
              />
            ))
          )}
        </div>
      </div>
      <Modal 
        visible={isComplete && !modalClosed}
        onClose={()=> setModalClosed(true)}
        className={css.modal}
      >
        <h2 className={css.modalTitle}>🎉 Congratulations!</h2>
        <div className={css.modalContent}>
          You completed the board in {moves} move{moves !== 1 ? 's' : ''}.
        </div>
        <div className={css.modalActions}>
          <CopyToClipboardButton 
            label='Share your score'
            copiedLabel='Copied to clipboard!'
            className={css.button} 
            clipboardContent={getClipboardText()} 
          />
        </div>
      </Modal>
    </>
  )
}

export default TileFlip