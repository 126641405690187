import css   from './Cover.module.css'

const Cover = ()=> (
  <div className={css.cover}>
    <div className={css.shadow} />
    <div className={css.content}>
      <p className={css.monkey} />
    </div>
  </div>
)

export default Cover